input[name='confirmationCode'],
input[name='username'],
input[type='password'],
input[type='email'] {
  background: transparent;
  border: none;
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.15);
  -webkit-appearance: none;
  padding: 15px 0;
  box-sizing: border-box;
  color: var(--font-color);
  width: 100%;
  margin-bottom: 30px;
  transition: all 250ms;
  border-radius: 0;
  transition: 250ms all;
}

input[name='confirmationCode']:focus,
input[name='username']:focus,
input[type='password']:focus,
input[type='email']:focus {
  box-shadow: inset 0 -2px 0 var(--primary-accent);
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--font-color);
  transition: background-color 5000s ease-in-out 0s;
}
.Login-Register-Container {
  position: relative;
  box-shadow: 0px 0px 10px black inset;
  width: 400px;
  padding: 20px;
}
.Login-Register-Container:before {
  content: '';
  backdrop-filter: blur(6px);
  background: rgba(15, 15, 15, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

input[value='Reset Password'],
input[value='Send Code'],
input[value='Confirm Account'],
input[value='Sign in'],
input[value='Create Account'] {
  width: 100%;
  box-sizing: border-box;
  background: var(--primary-accent);
  border: none;
  color: black;
  font-weight: 600;
  padding: 20px 0;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.2s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 10px;
}
.Auth-Body-Footer-Text {
  color: var(--font-color-alt);
  font-weight: 900;
  margin-top: 10px;
  cursor: pointer;
  transition: color 500ms;
  font-size: 1.2rem;
}
.Auth-Body-Footer-Text:hover {
  color: var(--font-color);
}
.Auth-Body-Placeholder-Text {
  margin: 0;
  padding: 0;
  text-align: left;
  text-transform: uppercase;
  color: var(--font-color-alt);
  font-weight: 900;
}
.Auth-Body-Secondary-Text {
  color: var(--font-color-alt);
  font-weight: 900;
  text-align: left;
  margin-bottom: 10px;
  margin-top: -15px;
  transition: color 500ms;
  cursor: pointer;
}
.Auth-Body-Secondary-Text:hover {
  color: var(--font-color);
}
.AuthenticationTab {
  color: var(--font-color-alt);
  font-weight: 900;
  margin-top: 10px;
  cursor: pointer;
  transition: color 500ms;
  font-size: 1.2rem;
}
.AuthenticationTab:hover {
  color: var(--font-color);
}
.AuthenticationTab-Hidden {
  display: none;
}
.Auth-Body-Error {
  margin-top: -15px;
  margin-bottom: 30px;
  font-weight: 900;
  text-align: left;
  font-size: 1.2rem;
  color: rgb(255, 120, 120);
}
