.Avatar {
  position: relative;
  display: block;
  margin-bottom: 5px;
  border: 2px solid var(--border-color);
  border-radius: 0px;
  width: 50px;
  height: 50px;
  aspect-ratio: 1/1;
  text-decoration: none;
  /* transition: all 0.3s; */
  background: var(--background-floating);
  box-sizing: unset !important;
  --border-color: #434651;
  --youtube-border: rgb(196, 48, 43);
  --twitch-border: rgb(145 70 255);
  --seiso-border: rgb(102, 252, 241);

  --status-live: #eb0400;
  --primary-accent: #66fcf1;
}
.Avatar-Variable-Height {
  height: inherit !important;
}
.Avatar-Status-Twitch {
  border-color: var(--twitch-border) !important;
  margin-bottom: 10px;

  box-shadow: 0 8px 14px -5px rgb(145 70 255 / 65%);
}
.Avatar-Status-Youtube {
  border-color: var(--youtube-border) !important;
  margin-bottom: 10px;

  box-shadow: 0 8px 14px -5px rgb(196 48 43 / 65%);
}
.Avatar-Status-Seiso {
  border-color: var(--primary-accent) !important;
  margin-bottom: 10px;

  box-shadow: 0 8px 10px -5px rgb(69 162 158 / 65%);
}
.Avatar-Image {
  height: inherit;
  width: inherit;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 1px;
  transition: border-radius 0.3s;
  object-fit: cover;
  object-position: center;
  pointer-events: none;
}
.Avatar-Status {
  position: absolute;
  bottom: 0px;
  left: 50%;
  right: 50%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: -10px;
  pointer-events: none;
  line-height: 15px;
}
.Avatar-Status-Text {
  border-radius: 4px;
  padding: 3px;
  display: flex;
  line-height: 15px;
}
.Avatar-Status-Text > span {
  border-radius: 0px;
  background: var(--status-live);
  color: white;
  text-transform: uppercase;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}

.Avatar-XXSmall {
  height: 25px;
  width: 25px;
}

.Avatar-XSmall {
  height: 40px;
  width: 40px;
}

.Avatar-Small {
  height: 45px;
  width: 45px;
}

.Avatar-Medium {
  height: 80px;
  width: 80px;
}

.Avatar-Large {
  height: 150px;
  width: 150px;
}
