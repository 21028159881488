.Hashtag-Follow-Button {
  height: 41px;
  /* background: #18181b; */
  box-shadow: 0px 0px 10px black inset;
  color: var(--primary-accent);
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  padding: 0 20px;
  cursor: pointer;
  transition: all 0.2s;
  box-sizing: border-box;
  margin-left: auto;
}
.Hashtag-Follow-Button:hover {
  background: #18181b;
}
