.Follow-List-Container {
  width: 100%;
  max-width: 750px;
  background: #0f0f0f;
  min-height: calc(100vh - 50px);
  border-left: 1px solid var(--border-color-alt);
  border-right: 1px solid var(--border-color-alt);
}
.Follow-List-Header {
  position: sticky;
  background: rgba(15, 15, 15, 0.75);
  border-bottom: 1px solid var(--border-color-alt);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  top: 50px;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
}
.Follow-List-Body {
  margin-top: 5px;
}
.Follow-List-Profile-List-Item-Container {
  padding: 6px 12px;
}
