.Nav-Notification-Menu {
  position: fixed;
  right: 0;
  top: 49px;
  z-index: 2;
  width: 300px;
  border: 1px solid #000;
  box-shadow: 0 5px 10px black !important;
  background-color: #0f0f0f;
}
.Nav-Notification-Menu-Options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Nav-Notification-Menu-Option {
  display: block;
  width: 100%;
  text-align: left;
  padding: 15px;
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--font-color-alt);
  cursor: pointer;
  border-bottom: 1px solid black;
  box-sizing: border-box;
}
@media (hover: hover) {
  .Nav-Notification-Menu-Option:hover {
    color: white;
    background: #18181b;
  }
}
.Nav-Notification-Menu-Option:last-child {
  border: none;
}
