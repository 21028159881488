.Profile-Editor-Selector {
  background-color: var(--background-floating);
  border-radius: 2px;
  border: 0px solid var(--seiso-border);
  color: var(--font-color);
  margin-bottom: 10px;
  outline: none;
  line-height: 2rem;
  font-size: 1.6rem;
  text-align: center;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.9);
}
.Add-New-Showcase-Button {
  padding-bottom: 6px;
  padding-bottom: 8px;
  padding: 10px 10px;
  margin: 0 10px;
  position: relative;
  box-shadow: 0 0px 6px black inset;
  margin-top: 10px;
  margin-bottom: 6px;
  display: flex;
  transition: height 1s;
  transition: all 0.5s;
}
.Add-New-Showcase-Button:hover {
  background: #18191c;
  box-shadow: 0 0px 6px black;
  cursor: pointer;
}
.Add-New-Showcase-Button > svg {
  margin: auto;
  color: var(--font-color-alt);
  font-size: 4rem;
}

.Add-New-Showcase-Button-Alt {
  padding-bottom: 6px;
  padding-bottom: 8px;
  padding: 10px 10px;
  margin: 0 10px;
  position: relative;
  box-shadow: 0 0px 6px black inset;
  margin-top: 10px;
  margin-bottom: 6px;
  display: flex;
  transition: height 1s;
}

.Add-New-Showcase-Options {
  width: 100%;
  padding: 10px 40px;
  column-count: 2;
}
.Add-New-Showcase-Options > button {
  width: 100%;
  margin: 5px 0px;
  padding: 10px;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  background-color: transparent;
  color: var(--font-color);
  cursor: pointer;
  border: none;
  box-shadow: 0 0px 6px black inset;
  transition: all 0.5s;
}
.Add-New-Showcase-Options > button:hover {
  background: #18191c;
  box-shadow: 0 0px 6px black;
}

.Profile-Content-Container-Editor {
  max-width: 100%;
  width: 100%;
  position: relative;
}
.Profile-Content-Container-Editor > .Editor-Block {
  margin: 0 10px;
}

.Profile-Editor-Tabs-Container {
  margin: auto;
  margin: 10px;
  margin-bottom: 25px;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  box-shadow: 0px 0px 10px black inset;
}
.Profile-Editor-Tabs-Container-Inner {
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  line-height: 47px;
  height: 48px;
  padding-bottom: 1px;
  display: flex;
  align-items: center;
  position: relative;
}
.Profile-Editor-Tab {
  display: block;
  padding: 0 32px;
  height: 100%;
  min-width: 100px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: var(--font-color);
}
.Profile-Editor-Tab-Active {
  padding: 0 32px;
  height: 100%;
  min-width: 100px;
  display: block;
  position: relative;
  cursor: pointer;
  text-align: center;
  color: var(--primary-accent);
  text-decoration: none;
}
.Profile-Editor-Tab-Active:before {
  content: '';
  position: absolute;
  border-bottom: 1px solid var(--primary-accent);
  left: 25%;
  bottom: 0;
  height: 1px;
  width: 50%;
}
.Profile-Editor-Tab:hover {
  color: var(--primary-accent);
}
.Profile-Editor-Tab-Active:hover {
  color: var(--primary-accent);
}
.Profile-Editor-Tabs-List-Item {
  height: 100%;
  position: relative;
}
.Profile-Editor-Tabs-List-Item-Options {
  position: absolute;
  z-index: 1;
  display: flex;
  top: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.Profile-Editor-Tabs-List-Item-Options * {
  box-sizing: border-box;
}
.Profile-Editor-Tabs-List-Item-Option {
  color: var(--font-color-alt);
  cursor: pointer;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Profile-Editor-Tabs-List-Item-Option:hover {
  color: var(--font-color);
}
.Profile-Editor-Tabs-List-Item-Option > svg {
  font-size: 2rem;
}

.Edit-Tabs-Icon {
  margin-left: auto;
  margin-right: 10px;
  padding: 2px;
  display: flex;
  align-items: center;
  color: var(--font-color-alt);
  cursor: pointer;
}
.Edit-Tabs-Icon:hover {
  color: var(--font-color);
}
.Edit-Tabs-Icon > svg {
  font-size: 2rem;
}
