.Profile-Settings-Container {
  max-width: 1280px;
  width: 100%;
  /* display: flex;
  flex-direction: column; */
  align-items: center;
  background: #0f0f0f;
}
.Profile-Settings-Flex {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 100px);
}

.Profile-Settings-Left {
  width: 280px;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  position: sticky;
  top: 54px;
  box-sizing: border-box;
}
/* .Profile-Settings-Left > a {
  text-decoration: none;
  font-size: 2rem;
  border-radius: 8px;
  padding: 5px 5px 5px 10px;
  margin-bottom: 2px;
}
.Profile-Settings-Left > a:hover {
  background: var(--background-hover-alt);
}
.Setting-Link-Active {
  background: var(--background-hover-alt);
} */

.Profile-Settings-Right {
  width: calc(100% - 280px);
  min-height: calc(100vh - 50px);
  box-sizing: border-box;
  padding-bottom: 50px;
  box-sizing: border-box;
}

.Profile-Settings-Save-Buttons {
  display: flex;
  align-self: flex-end;
  margin-bottom: 8px;
  margin-top: 8px;
}
.Profile-Settings-Save-Buttons > div {
  margin-right: 10px;
}

.Profile-Settings-Left > a {
  width: 100%;
  padding: 10px;
  font-size: 1.8rem;
  cursor: pointer;
  color: var(--font-color-alt);
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 2px 0px;
  box-sizing: border-box;
  border-bottom: 1px solid transparent;
  box-shadow: 0 0 6px rgb(0 0 0) inset;
  transition: all 0.5s;
}
.Profile-Settings-Left > a:hover {
  background: #18181b;
  color: var(--font-color);
  box-shadow: 0 0 6px rgb(0 0 0);
}
.Setting-Link-Active {
  background: #18181b;
  color: var(--font-color) !important;
  box-shadow: 0 0 6px rgb(0 0 0) !important;
}
.Profile-Settings-Spacer {
  width: 100%;
  border-bottom: 2px solid var(--border-color-alt);
  margin: 10px 0px;
  font-size: 1.5rem;
  color: var(--font-color-alt);
}
.Profile-Settings-Spacer:first-child {
  margin-top: 0;
}

.Profile-Settings-Mobile-Menu-Button {
  color: white;
  position: fixed;
  top: 60px;
  left: 10px;
  box-shadow: 0 0 6px rgb(0 0 0) inset;
  background: #0f0f0f;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  display: none;
  z-index: 1;
}
.Profile-Settings-Mobile-Menu-Button > svg {
  font-size: 2.5rem;
}
.Tab-Header-Mobile {
  display: none;
}

@media screen and (max-width: 950px) {
  .Tab-Header-Mobile {
    display: block;
    margin-bottom: 45px;
  }
  .Profile-Settings-Mobile-Menu-Button {
    display: flex;
  }
  .Profile-Settings-Flex {
    flex-direction: column;
  }
  .Profile-Settings-Left {
    width: 240px;
    position: fixed;
    top: 50px;
    bottom: 0;
    left: -240px;
    background: #0f0f0f;
    z-index: 2;
    box-shadow: 0 0 6px rgb(0 0 0) inset;
    transition: all 500ms;
  }
  .Profile-Settings-Left-Mobile-Menu-Open {
    left: 0;
  }
  .Profile-Settings-Spacer {
    display: none;
  }
  .Profile-Settings-Right {
    width: 100%;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
