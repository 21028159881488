.Feed-Container {
  width: 100%;
  max-width: 750px;
  background: #0f0f0f;
  /* border-right: 1px solid rgb(37, 40, 43);
  border-left: 1px solid rgb(37, 40, 43); */
  border-right: 1px solid rgb(37, 40, 43);
  border-left: 1px solid rgb(37, 40, 43);
  min-height: calc(100vh - 50px);
  box-sizing: border-box;
}
.Feed-Container-Thread {
  min-height: calc(100vh - 100px);
}

.No-Feed-Items-Message {
  color: var(--font-color-alt);
  font-size: 1.5rem;
  padding: 30px;
}

.New-Posts-Notification {
  cursor: pointer;
  box-sizing: border-box;
  position: sticky;
  top: 50px;
  box-shadow: 0px 0px 10px black inset;
  background: rgba(15, 15, 15, 0.5);
  backdrop-filter: blur(8px);
  padding: 12px 12px;
  margin: 6px 0px;
  margin-top: -6px;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--primary-accent);
  z-index: 1;
  border-bottom: 1px solid var(--border-color-alt);
}
@media (hover: hover) {
  .New-Posts-Notification:hover {
    background: #18181b;
  }
}

.Post-Loading-Placeholder {
  width: 100%;
  height: 130px;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 12px 12px;
  box-sizing: border-box;
  box-shadow: 0 1px 5px black inset;
  border-bottom: 2px solid var(--border-color-alt);
}
.Post-Loading-Placeholder:after {
  content: '';
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  top: 15px;
  left: 15px;
  position: absolute;
  background-image: linear-gradient(100deg, transparent, rgba(31, 31, 35, 0.5) 50%, transparent 80%),
    linear-gradient(var(--background-floating) 50px, transparent 0),
    linear-gradient(var(--background-floating) 24px, transparent 0),
    linear-gradient(var(--background-floating) 28px, transparent 0),
    linear-gradient(var(--background-floating) 28px, transparent 0);
  background-repeat: no-repeat;
  background-size: 75px 130px, 55px 56px, 30% 30px, 100% 20px, 70% 20px;
  background-position: 0% 0, 0 0, 70px 0px, 70px 38px, 70px 66px;
  -webkit-animation: animloader124 1s linear infinite;
  animation: animloader 1s linear infinite;
}

@keyframes animloader {
  0% {
    background-position: -20% 0, 0 0px, 70px 0px, 70px 38px, 70px 66px;
  }
  100% {
    background-position: 150% 0, 0 0px, 70px 0px, 70px 38px, 70px 66px;
  }
}
