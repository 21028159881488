.Image-Block-Container {
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.Image-Block-Container-Alt {
  grid-column: 1 / 2;
  display: grid;
  gap: 10px;
}

.Image-Block-Item {
  width: 100%;
  aspect-ratio: 16/12;
  border-radius: 0px;
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  flex-flow: column;
  position: relative;
  /* box-shadow: 0px 0px 10px black; */
  background: #18181b;
  box-shadow: 0px 0px 10px black inset;
}
.Image-Block-Item-Alt {
  width: 100%;
  aspect-ratio: 16/10;
  border-radius: 0px;
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  flex-flow: column;
  position: relative;
  box-shadow: 0px 0px 10px black;
  background: #18181b;
}
@media screen and (max-width: 650px) {
  .Image-Block-Container {
    display: unset;
  }
  .Image-Block-Container-Alt {
    display: unset;
  }
  .Image-Block-Item {
    margin-bottom: 10px;
  }
  .Image-Block-Item:last-child {
    margin-bottom: 0px;
  }
}
.Image-Block-Item-Twitch {
  border-color: var(--twitch-border) !important;
}
.Image-Block-Item-Youtube {
  border-color: var(--youtube-border) !important;
}
.Image-Block-Link {
  min-width: 0px;
  display: inline-block;
  margin: 8px 0px 0px;
  color: var(--primary-accent);
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
.Image-Block-Link:hover {
  text-decoration: underline;
}
.Image-Block-Desc {
  color: var(--font-color);
  min-width: 0px;
  margin: 8px 0px 0px;
  text-align: left;
  font-size: 16px;
}
.Image-Block-Margin {
  margin-top: 16px;
  height: calc(100% - 247px);
  flex: 1 1 auto;
}
.Image-Block-Margin-Alt {
  margin-top: 0px;
  height: calc(100% - 247px);
  flex: 1 1 auto;
}
.Image-Block-Item-Image-Grid {
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 4px;
  height: 100%;
  border-radius: 0px;
  overflow: hidden;
}
.Image-Block-Item-Image-NoGrid {
  display: grid;
  height: 100%;
  border-radius: 0px;
  overflow: hidden;
}
.Image-Block-Item-Image-Container {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
  grid-row: span 2 / auto;
  position: relative;
}
.Image-Block-Item-Image-Container-Alt {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
  position: relative;
}
.Image-Block-Item-Image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.Image-Block-Item-Editbutton {
  position: absolute;
  top: 50%;
  left: calc(50% - 27px);
  background: #18191c;
  border-radius: 0px;
  padding: 10px 12px;
  cursor: pointer;
}
.Image-Block-Item-Editbutton > svg {
  color: var(--font-color);
  font-size: 3rem;
}
