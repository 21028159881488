.Panel-Showcase-Item {
  border-radius: 0px;
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  flex-flow: column;
  position: relative;
  /* box-shadow: 0px 0px 10px black; */
  background: #18181b;
  color: var(--font-color);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px black inset;
}
.Panel-Showcase-Img {
  width: 100%;
}
.Panel-Showcase-Img-Link {
  display: block;
  width: 100%;
}
.Panel-Showcase-Text {
  font-size: 1.3rem;
  margin-top: 10px;
  text-align: left;
}
.Panel-Showcase-Text * > a {
  color: var(--primary-accent);
  text-decoration: none;
}
.Panel-Showcase-Text * > a:hover {
  text-decoration: underline;
}
