.Browse-Container {
  min-height: calc(100vh - 50px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Browse-Container-Inner {
  width: 100%;
  max-width: 1000px;
  background: #0f0f0f;
  min-height: calc(100vh - 50px);
  border-left: 1px solid var(--border-color-alt);
  border-right: 1px solid var(--border-color-alt);
}
.Browse-Preview-Header {
  font-size: 2rem;
  font-weight: 600;
  color: var(--primary-accent);
  font-family: 'fujimaru';
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid var(--border-color-alt);
}
.Browse-Preview-Header > span {
  filter: brightness(75%);
}
.Browse-Live-Profiles-Preview {
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.Browse-Live-Profiles-Preview-Item {
  width: 400px;
  height: 245px;
  margin: 15px;
  justify-items: center;
  position: relative;
  box-shadow: 0px 0px 10px black inset;
  cursor: pointer;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.Browse-Live-Profiles-Preview-Item:hover {
  outline: 1px solid var(--primary-accent);
}
.Browse-Live-Profiles-Preview-Item-Img {
  width: 100%;
}
.Browse-Live-Profiles-Preview-Item-Info {
  display: flex;
  text-align: left;
  margin-top: 5px;
}
.Browse-Live-Profiles-Preview-Item-Info-R {
  margin-left: 5px;
}
.Browse-Live-Profiles-Preview-Item-Info-Displayname {
  font-weight: 600;
  font-size: 1.3rem;
  display: flex;
}
.Browse-Live-Profiles-Preview-Item-Info-Handle {
  color: var(--font-color-alt);
  font-size: 1rem;
  margin-left: 5px;
}
.Browse-Live-Profiles-Preview-Item-Info-Displayname-Emoji {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 2px;
  margin-right: 2px;
  vertical-align: -20%;
}
.Browse-Live-Profiles-Preview-Item-Info-Status {
  color: var(--font-color-alt);
  font-weight: 600;
}

.Browse-Popular-Hashtags {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.Browse-Popular-Hashtags-Item {
  flex: 1 290px;
  padding: 10px;
  box-shadow: 0px 0px 10px black inset;
  margin: 5px;
  text-align: left;
  cursor: pointer;
}
.Browse-Popular-Hashtags-Item:hover {
  background: #18181b;
}
.Browse-Popular-Hashtags-Item-Top {
  font-size: 2rem;
  color: var(--primary-accent);
}
.Browse-Popular-Hashtags-Item-Bottom {
  font-size: 1.5rem;
  color: var(--font-color-alt);
}
.Trending-Posts-Preview {
  display: flex;
  flex-direction: column;
}
.Trending-Posts-Preview > .Post-Container {
  width: unset;
  max-width: unset;
}
