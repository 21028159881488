.Crop-Image-Modal-Container {
  position: fixed;
  top: 100px;
  max-width: 80vw;
  max-height: 90vh;
  background: rgb(24, 24, 27);
  box-shadow: 0 5px 10px black !important;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 0px;
  padding: 10px 30px 10px 30px;
  display: flex;
  flex-direction: column;
  z-index: 2;
}
.Image-Cropper {
  align-self: center;
}
.Crop-Image-Modal-Preview {
  max-height: 70vh !important;
}
.Crop-Controls {
  display: flex;
  margin-bottom: 10px;
  align-self: center;
}
.Crop-Icons-Container {
  display: flex;
  align-self: center;
}
.Crop-Icon {
  height: 3rem !important;
  width: 3rem !important;
  cursor: pointer;
}
.Crop-Icon-Active {
  height: 3rem !important;
  width: 3rem !important;
  cursor: pointer;
}
.Crop-Icon-Active * {
  color: var(--primary-accent);
}
