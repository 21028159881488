.Video-Showcase-Container {
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.Video-Showcase-Container-Alt {
  grid-column: 1 / 2;
  display: grid;
  gap: 10px;
}
.Video-Showcase-Item {
  position: relative;
  width: 100%;
  border-radius: 0px;
  border-left: 2px solid var(--border-color);
  padding: 0.5rem 1rem 1rem 0.75rem;
  display: inline-grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  background: #18181b;
  box-shadow: 0px 0px 10px black inset;
}
@media screen and (max-width: 650px) {
  .Video-Showcase-Container {
    display: unset;
  }
  .Video-Showcase-Container-Alt {
    display: unset;
  }
  .Video-Showcase-Item {
    margin-bottom: 10px;
  }
  .Video-Showcase-Item:last-child {
    margin-bottom: 0px;
  }
}
.Video-Showcase-Item-Twitch {
  border-color: var(--twitch-purple) !important;
}
.Video-Showcase-Item-Youtube {
  border-color: var(--youtube-red) !important;
}
.Video-Showcase-Link {
  min-width: 0px;
  display: inline-block;
  margin: 8px 0px 0px;
  color: var(--primary-accent);
  text-decoration: none;
  font-size: 20px;
  height: 30px;
  font-weight: 600;
  text-align: left;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
.Video-Showcase-Link:hover {
  text-decoration: underline;
}
.Video-Showcase-Desc {
  color: var(--font-color);
  min-width: 0px;
  margin: 8px 0px 0px;
  text-align: left;
  font-size: 16px;
}
.Video-Showcase-Item-Image {
  min-width: 0px;
  width: 100%;
  margin: 16px 0px 0px;
  border-radius: 0px;
  cursor: pointer;
}
.Video-Showcase-Item-Embed {
  min-width: 0px;
  aspect-ratio: 16/9;
  width: 100%;
  margin: 16px 0px 0px;
  border-radius: 0px;
  cursor: pointer;
}
.Video-Showcase-Item-Playbutton {
  position: absolute;
  top: 50%;
  left: calc(50% - 27px);
  /* background: #18191c; */
  background: rgba(24, 25, 28, 0.7);
  box-shadow: 0px 0px 10px black inset;
  /* border-radius: 16px; */
  padding: 10px 12px;
  cursor: pointer;
}
.Video-Showcase-Item-Playbutton > svg {
  color: var(--font-color);
  font-size: 3rem;
}
