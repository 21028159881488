.Profile-List-Item {
  box-shadow: 0px 0px 10px black inset;
  padding: 16px 12px;
  border-bottom: 2px solid var(--border-color-alt);
  display: flex;
  max-width: 750px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  box-sizing: border-box;
}
.Profile-List-Item:hover {
  background: rgba(0, 0, 0, 0.5);
}
.Profile-List-Info-Container {
  margin-left: 10px;
  width: 100%;
}
.Profile-List-Info-Top {
  display: flex;
  width: 100%;
}
.Profile-List-Username {
  color: var(--font-color);
  overflow-wrap: break-word;
  font-size: 1.6rem;
  font-weight: 700;
}
.Profile-List-Username:hover {
  text-decoration: underline;
}
.Profile-List-Handle {
  display: block;
  color: var(--font-color-alt);
  overflow-wrap: break-word;
  margin-left: 4px;
  font-size: 1.4rem;
  margin-top: 4px;
}
.Profile-List-Socials {
  margin-left: auto;
}
.Profile-List-Status {
  display: block;
  white-space: pre-wrap;
  font-weight: 400;
  font-size: 1.5rem;
  color: var(--font-color);
  margin-top: 5px;
  margin-left: 4px;
}
.Profile-List-Status * > p {
  margin: 0;
}
.Profile-List-Social-Icon-Container > .Social-Icon:hover {
  -webkit-box-reflect: unset !important;
}
