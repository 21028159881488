.Mini-Profile-Wrapper {
  position: fixed;
  width: 400px;
  top: 50%;
  z-index: 10;
  display: none;
  opacity: 0;
  margin: 10px;
}
.Mini-Profile-Wrapper-Visible {
  transition: opacity 200ms ease-in;
  opacity: 1;
}
.Mini-Profile-Wrapper-Editing {
  position: relative;
  width: 400px;
  z-index: 0;
}

.Mini-Profile-Inner {
  /* margin: 10px; */
  box-shadow: 0 0 6px rgb(0 0 0);
  overflow: hidden;
}
.Mini-Profile-Container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
  font-family: 'Motiva Sans', Sans-serif;
  font-weight: normal;
}

.Mini-Profile-Background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.Mini-Profile-Background-Video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.Mini-Profile-User-Section {
  position: relative;
  display: flex;
  z-index: 1;
  height: 58px;
  padding: 16px;
  padding-bottom: 20px;
}
.Mini-Profile-Avatar-Container {
  width: 84px;
  height: 84px;
}
.Mini-Profile-User-Name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
  color: var(--font-color);
  text-shadow: 1px 1px 1px black;
  /* text-shadow: 1px 1px 4px black; */
}
.Mini-Profile-Displayname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 270px;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: left;
  color: var(--font-color);
  text-shadow: 1px 1px 1px black;
  display: flex;
}
.Mini-Profile-Displayname > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Mini-Profile-Displayname > div:last-child {
  overflow: unset;
}
.Mini-Profile-Displayname-Emoji {
  height: 2rem;
  width: 2rem;
  margin-left: 3px;
  margin-right: 3px;
  vertical-align: -10%;
}
.Mini-Profile-Handle {
  /* color: var(--font-color-alt); */
  color: rgb(154, 154, 154);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 270px;
  text-shadow: 1px 1px 1px black;
  text-align: left;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.8rem;
}
.Mini-Profile-Stats {
  max-width: 100%;
  height: 30px;
  position: relative;
  z-index: 1;
  padding: 5px;
  display: flex;
  justify-content: space-evenly;
  text-shadow: 1px 1px 1px black;
}
.Mini-Profile-Stats > span {
  margin: 10px;
  color: var(--font-color);
  font-size: 1.3rem;
  margin-top: 5px;
  margin-right: 20px;
  margin-left: 15px;
  margin-bottom: 0;
  text-align: center;
  font-weight: 500;
}

.Mini-Profile-Status-Section {
  background: linear-gradient(to bottom, rgba(12, 12, 14, 0.7) 5%, rgba(12, 12, 14, 0.4) 95%);
  backdrop-filter: blur(3px);
  display: flex;
  position: relative;
  min-height: 70px;
  box-shadow: inset 0 0 5px black;
}
.Mini-Profile-Status-Details {
  display: flex;
  flex-direction: column;
  padding: 8px 20px 8px 20px;
  font-size: 14px;
  align-self: center;
  text-align: left;
}
.Mini-Profile-Status-Details-Text {
  font-size: 1.3rem;
  /* color: var(--font-color-alt); */
  color: rgb(154, 154, 154);
  text-shadow: 1px 1px 1px black;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  padding: 10px 15px;
  padding-bottom: 0;
  line-height: 1.8rem;
}
.Mini-Profile-Status-Details-Text * > p {
  margin: 0;
}

.Mini-Profile-Details-Section {
  display: flex;
  flex-direction: column;
  padding: 8px 8px 8px 8px;
}
.Mini-Profile-Follow-Button {
  height: 41px;
  margin-top: 5px;
  background: #18181b;
  color: var(--primary-accent);
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  padding: 0 18px;
  cursor: pointer;
  box-shadow: 0 8px 14px -4px rgb(200 200 200 / 35%);
  transition: all 0.2s;
  box-sizing: border-box;
  flex-grow: 1;
}
.Mini-Profile-Follow-Button > svg {
  font-size: 2rem;
}
.Mini-Profile-Follow-Button:hover {
  color: black;
  opacity: 0.9;
  background: var(--primary-accent);
  box-shadow: 0px 0px 5px var(--primary-accent), 0px 0px 10px var(--primary-accent), 0px 0px 20px var(--primary-accent),
    0px 0px 10px var(--primary-accent);
}
/* .Mini-Profile-Social-Icon-Container {
  margin-right: 10px;
} */
.Mini-Profile-Social-Icon-Container:hover > .Social-Icon {
  -webkit-box-reflect: unset !important;
}

.Mini-Profile-Message-Button {
  height: 41px;
  width: 41px;
  margin-top: 5px;
  background: #18181b;
  color: var(--primary-accent);
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  padding: 0 20px;
  cursor: pointer;
  box-shadow: 0 8px 14px -4px rgb(200 200 200 / 35%);
  transition: all 0.2s;
  white-space: nowrap;
  box-sizing: border-box;
}
.Mini-Profile-Message-Button > svg {
  font-size: 2rem;
}
.Mini-Profile-Message-Button:hover {
  color: black;
  opacity: 0.9;
  background: var(--primary-accent);
  box-shadow: 0px 0px 5px var(--primary-accent), 0px 0px 10px var(--primary-accent), 0px 0px 20px var(--primary-accent),
    0px 0px 10px var(--primary-accent);
}
.Mini-Profile-Socials {
  display: flex;
  justify-content: center;
}
