.Nav-Profile-Menu {
  position: fixed;
  right: 0;
  top: 49px;
  z-index: 2;
  width: 300px;
  border: 1px solid #000;
  box-shadow: 0 5px 10px black !important;
  background-color: #0f0f0f;
}
.Nav-Profile-Menu-Options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Nav-Profile-Menu-Option {
  display: block;
  width: 100%;
  text-align: left;
  padding: 15px;
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--font-color-alt);
  cursor: pointer;
  border-bottom: 1px solid black;
  box-sizing: border-box;
}
@media (hover: hover) {
  .Nav-Profile-Menu-Option:hover {
    color: white;
    background: #18181b;
  }
}
.Nav-Profile-Menu-Option:last-child {
  border: none;
}
.Nav-Profile-Menu-Option-Logout {
  color: rgb(255, 120, 120);
}
.Status-Settings-Sub-Header {
  color: var(--font-color-alt);
  font-size: 1.3rem;
  font-weight: 900;
  text-align: left;
  display: block;
  width: 95%;
  margin: auto;
  margin-bottom: 4px;
  text-transform: uppercase;
  margin-top: 10px;
}
.Status-Switch-Container {
  position: relative;
  background: #18181b;
  z-index: 1;
}
.Status-Switch-Main {
  height: 50px;
  width: 95%;
  box-shadow: 0 0 10px rgb(0 0 0) inset;
  padding: 10px 10px;
  box-sizing: border-box;
  margin: auto;
  font-size: 1.5rem;
  color: var(--font-color-alt);
  text-transform: uppercase;
  text-align: left;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.Status-Switch-Main > svg {
  font-size: 2rem;
  margin-left: auto;
}
.Status-Switch-Menu {
  position: absolute;
  width: 95%;

  left: 2.5%;
  right: 2.5%;
  top: 50px;
  box-shadow: 0 0 10px rgb(0 0 0) inset;
}
.Status-Switch-Menu-Item {
  height: 50px;
  padding: 10px 10px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: var(--font-color-alt);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.5rem;
  cursor: pointer;
  border-top: 1px solid var(--border-color-alt);
  background: #18181b;
  box-shadow: 0 0 10px rgb(0 0 0) inset;
}
@media (hover: hover) {
  .Status-Switch-Menu-Item:hover {
    color: var(--font-color);
  }
}
