.Profile-Header {
  display: flex;
  width: 100%;
  max-width: var(--profile-max-width);
  /* border-bottom: 1px solid #000; */
  /* box-shadow: 0 1px 3px rgb(0 0 0 / 90%); */
}
.Profile-Header-Streamer-Mode {
  max-width: 100vw;
  width: calc(100vw - 14px);
  background: #0f0f0f;
}
.Profile-Header-Inner {
  max-width: 100%;
  width: 100%;
}
.Profile-Header-Inner-Streamer-Mode {
  max-width: calc(100% - 350px);
}

.Profile-Header-Avatar-Negative-Margin {
  margin: 0;
  margin-top: -90px;
}

.Profile-Banner-Container {
  background: #0f0f0f;
  max-width: var(--profile-max-width);
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
  max-height: 250px;
}
.Profile-Banner-Container > img {
  width: 100%;
  aspect-ratio: 4/1;
  max-height: 250px;
  object-fit: cover;
  display: block;
  -webkit-box-reflect: below 1px linear-gradient(transparent, rgba(0, 0, 0, 0.7));
}
.Profile-Banner-Spacer {
  max-width: var(--profile-max-width);
  width: 100%;
  aspect-ratio: 4/1;
  pointer-events: none;
  max-height: 250px;
  border-bottom: 1px solid var(--border-color-alt);
}

.Profile-Info {
  background-color: #0f0f0f;
  max-width: var(--profile-max-width);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  background-color: rgba(15, 15, 15, 0.7);
  backdrop-filter: blur(6px);
  box-shadow: 0px 0px 10px black inset;
}
.Profile-Info-Streaming-Active {
  max-width: 100%;
}
.Profile-Info-Inner {
  max-width: var(--profile-max-width);
  width: 100%;
  display: flex;
  /* padding-bottom: 20px; */
  margin-bottom: 5px;
}
@media screen and (max-width: 650px) {
  .Profile-Info-Inner {
    flex-direction: column-reverse;
  }
  .Profile-Header-Main-Stream-Elements {
    max-width: 100%;
  }
}
.Profile-Info-Inner-Streaming-Active {
  max-width: 100%;
  box-sizing: border-box;
}

.Profile-Info-L {
  display: flex;
  /* width: calc(100% - 364px); */
}
.Profile-Info-R {
  max-width: 364px;
  justify-content: flex-end;
  justify-self: flex-end;
  margin-left: auto;
  display: flex;
}
.Profile-Info-C {
  margin-left: 10px;
}
.Profile-Info-Stack {
  text-align: left;
  /* margin-left: 10px; */
  width: calc(100%);
}

@media screen and (max-width: 650px) {
  .Profile-Info-R {
    margin: 0;
    margin-bottom: 20px;
  }
  .Profile-Info-L {
    width: 100%;
  }
  .Profile-Info-L-Stack {
    width: calc(100% - 84px);
  }
}

.Profile-Counts {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  /* margin-left: 10px; */
  margin-top: 10px;
  /* padding: 10px; */
}
.Profile-Counts > a {
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;
  color: var(--font-color);
}
.Profile-Counts > a:hover {
  text-decoration: underline;
}
.Profile-Counts > a:first-child {
  padding-left: 0;
}
.Profile-Counts > a > h3 {
  color: var(--font-color-alt);
  margin: 0;
}
.Profile-Counts > a {
  font-weight: 600;
}
.Profile-Counts-Alt {
  position: relative;
}
.Profile-Counts-Alt:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 20%;
  width: 2px;
  height: 60%;
  box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.5), 0 0px 0px rgba(0, 0, 0, 0.9) !important;
}
.Profile-Counts-Alt:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 20%;
  height: 60%;
  width: 2px;
  box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.5), 0 0px 0px rgba(0, 0, 0, 0.9) !important;
}

.Profile-Status {
  margin-top: 5px;
  font-size: 1.5rem;
  color: var(--font-color-alt);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  width: 100%;
  text-align: left;
}
.Profile-Status * > p {
  margin: 0;
}

.Profile-Follow-Button {
  height: 41px;
  margin-top: 5px;
  background: #18181b;
  color: var(--primary-accent);
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  padding: 0 20px;
  cursor: pointer;
  box-shadow: rgb(39, 41, 44) 0 8px 14px -4px;
  transition: all 0.2s;
  white-space: nowrap;
  box-sizing: border-box;
}
.Profile-Follow-Button:hover {
  color: black;
  opacity: 0.9;
  background: var(--primary-accent);
  box-shadow: 0px 0px 5px var(--primary-accent), 0px 0px 10px var(--primary-accent), 0px 0px 20px var(--primary-accent),
    0px 0px 10px var(--primary-accent);
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
}

.Profile-Follow-Button-Active {
  width: 41px;
}
.Profile-Follow-Button-Active > .Break-Heart {
  display: none;
}
.Profile-Follow-Button-Active:hover {
  background: rgb(255, 120, 120);
  box-shadow: 0px 0px 5px rgb(255, 120, 120), 0px 0px 10px rgb(255, 120, 120), 0px 0px 20px rgb(255, 120, 120),
    0px 0px 10px rgb(255, 120, 120);
}
.Profile-Follow-Button-Active:hover > .Break-Heart {
  display: unset !important;
}
.Profile-Follow-Button-Active:hover > .Full-Heart {
  display: none;
}
.Profile-Follow-Button > svg {
  font-size: 2rem;
}
.Profile-Message-Button {
  height: 41px;
  width: 41px;
  margin-top: 5px;
  background: #18181b;
  color: var(--primary-accent);
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  padding: 0 20px;
  cursor: pointer;
  box-shadow: rgb(39, 41, 44) 0 8px 14px -4px;
  transition: all 0.2s;
  white-space: nowrap;
  box-sizing: border-box;
}
.Profile-Message-Button > svg {
  font-size: 2rem;
}
.Profile-Message-Button:hover {
  color: black;
  opacity: 0.9;
  background: var(--primary-accent);
  box-shadow: 0px 0px 5px var(--primary-accent), 0px 0px 10px var(--primary-accent), 0px 0px 20px var(--primary-accent),
    0px 0px 10px var(--primary-accent);
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
}

.Profile-Username-Emoji {
  height: 2.5rem;
  width: 2.5rem;
  margin-left: 3px;
  margin-right: 3px;
  vertical-align: -10%;
}
@media screen and (max-width: 650px) {
  .Profile-Username-Emoji {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.Profile-Options {
  display: flex;
  align-items: center;
  position: relative;
  overflow: unset !important;
}
.Profile-Option {
  color: var(--font-color-alt);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  padding-left: 18px;
  padding-right: 18px;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  overflow: hidden;
  position: relative;
}
.Profile-Option > svg {
  font-size: 2rem;
}
.Profile-Option:hover {
  color: var(--font-color);
}

.Profile-Username {
  font-size: 2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  /* margin-top: 5px; */
  margin-bottom: 2px;
}
@media screen and (max-width: 650px) {
  .Profile-Username {
    font-size: 1.5rem;
  }
}

.Profile-Username > div {
  -webkit-line-clamp: 1;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Profile-Handle {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--font-color-alt);
  display: block;
  /* margin-bottom: 10px; */
  margin-left: 10px;
}

.Profile-Stream-Status {
  font-size: 1.3rem;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
  /* text-align: right; */
  /* margin-bottom: 5px; */
  /* margin-left: 10px; */
}
.Profile-Twitch-Link {
  color: var(--twitch-purple);
  cursor: pointer;
  margin-left: 5px;
}
.Profile-Twitch-Link:hover {
  text-decoration: underline;
}
.Profile-Youtube-Link {
  color: var(--youtube-red);
  cursor: pointer;
  margin-left: 5px;
}
.Profile-Youtube-Link:hover {
  text-decoration: underline;
}
