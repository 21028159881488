.Report-Modal-Container {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background: rgba(24, 24, 27, 0.5);
  backdrop-filter: blur(8px);
  text-align: center;
}
.Report-Modal-Container-Inner {
  position: relative;
  max-width: 740px;
  width: 100%;
  height: 600px;
  box-shadow: rgb(39, 41, 44) 0px 2px 12px;
  /* box-shadow: 0px 0px 10px black inset; */
  background: #18181b;
}
.Report-Fields-Wrapper {
  box-shadow: 0px 0px 10px black inset;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
}
.Report-Fields-Wrapper > h1 {
  margin: 0;
  font-weight: 600;
  color: var(--font-color-alt);
  font-size: 2rem;
}
.Report-Fields-Wrapper > span {
  display: block;
  margin: 0;
  font-weight: 600;
  font-size: 1.5rem;
  color: var(--font-color-alt);
  margin-top: 30px;
}
.Report-Fields-Wrapper > textarea {
  width: 100%;
  background-color: var(--background-floating);
  border: 2px solid transparent;
  outline: none;
  color: rgb(220, 221, 222);
  font-size: 1.6rem;
  padding: 10px 10px;
  resize: none;
  transition: 150ms;
  transition-property: background-color, border-color, color;
  unicode-bidi: plaintext;
  overflow-x: hidden;
  overflow-wrap: break-word;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgb(0 0 0) inset;
  margin-top: 30px;
  box-sizing: border-box;
  height: calc(100% - 153px);
}
.Create-Report-Button {
  padding: 9px 30px;
  font-size: 1.6rem;
  background: #18181b;
  color: var(--primary-accent);
  font-weight: 600;
  cursor: pointer;
  border: 1px solid black;
  box-sizing: border-box;
  transition: all 0.2s;
  margin-top: 5px;
}
.Create-Report-Button:hover {
  color: black;
  opacity: 0.9;
  background: var(--primary-accent);
  border: 1px solid transparent;
  box-shadow: 0px 0px 5px var(--primary-accent), 0px 0px 10px var(--primary-accent), 0px 0px 20px var(--primary-accent),
    0px 0px 10px var(--primary-accent);
}
.Close-Report-Modal {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.Close-Report-Modal > svg {
  font-size: 3rem;
  color: var(--font-color-alt);
}
.Close-Report-Modal:hover > svg {
  color: var(--font-color);
}
