.Tab-Name-Modal-Container {
  position: relative;
  z-index: 1;
  width: 350px;
  background: var(--app-background);
  margin-top: auto;
  margin-bottom: auto;
  overflow-y: scroll;
  pointer-events: auto;
  background-color: var(--background-floating);
  box-shadow: 0 5px 10px black;
  border: 1px solid black;
  z-index: 5;
}
.Tab-Name-Modal-Container > .Quick-Options-Button-Container {
  margin-top: 0;
}
.Tab-Name-Input-Bar {
  width: calc(100% - 0px);
  height: 50px;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  box-shadow: 0 1px 2px rgb(0 0 0 / 90%);
  background-color: var(--header-background);
  position: sticky;
  top: 0px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 5px;
}
.Tab-Name-Input-Bar > input {
  width: 100%;
  background: var(--background-primary);
  outline: none;
  box-shadow: none;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid black;
  transition: border 0.1s;
  color: var(--font-color);

  font-size: 1.5rem;
}
