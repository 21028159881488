.Profile-Options-Save-Indicator {
  height: 0px;
  width: 100%;
  position: sticky;
  z-index: 5;
  top: 102vh;
  display: flex;
  justify-content: center;
}
.Profile-Options-Save-Indicator-Inner {
  width: 80%;
  height: 40px;
  background: white;
  display: none;
  background-color: var(--background-floating) !important;
  box-shadow: 0 5px 10px black !important;
  border: 1px solid black !important;
  padding: 2px 10px;
  /* border-radius: 3px; */
  transition: all 500ms;
}
.Profile-Options-Save-Indicator-Inner > p {
  font-size: 1.2rem;
  margin: 0;
  width: auto;
  color: var(--font-color);
}

.Profile-Options-Save-Indicator-Buttons {
  margin-left: auto;
}

.Profile-Options-Save-Indicator-Button-1 {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 4px 20px;
  /* border-radius: 8px; */
  color: var(--font-color);
}
.Profile-Options-Save-Indicator-Button-1:hover {
  text-decoration: underline;
}
.Profile-Options-Save-Indicator-Button-2 {
  background: var(--primary-accent);
  color: black;
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 4px 20px;
  /* border-radius: 8px; */
  border: none;
}
.Profile-Options-Save-Indicator-Button-2:hover {
  filter: brightness(80%);
}

.Profile-Options-Save-Indicator-Slide-In {
  -webkit-animation: slide-in 0.5s forwards;
  animation: slide-in 0.5s forwards;
}
.Profile-Options-Save-Indicator-Slide-In > .Profile-Options-Save-Indicator-Inner {
  display: flex;
  align-items: center;
}

@-webkit-keyframes slide-in {
  100% {
    top: calc(100vh - 50px);
  }
}

@keyframes slide-in {
  100% {
    top: calc(100vh - 50px);
  }
}
