.Mini-Profile-Hexagon-Container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
  background: black;
  z-index: -1;
}

.Mini-Profile-Hex-Row {
  display: inline-flex;
  margin-left: -98px;
  margin-top: -18px;
  overflow: hidden;
}
.Mini-Profile-Hex-Row:nth-child(even) {
  margin-left: 1px;
}
.Mini-Profile-Hexagon {
  position: relative;
  height: 70px;
  width: 62px;
  background: #111;
  margin: 2px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  transition: 2s;
}
.Mini-Profile-Hex-Row:nth-child(odd) > .Mini-Profile-Hexagon:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.1);
}
.Mini-Profile-Hex-Row:nth-child(even) > .Mini-Profile-Hexagon:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.1);
}

.Mini-Profile-BG-Glow {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: absolute;
  box-shadow: 2px -3px 41px -1px rgba(241, 196, 15, 0.64);
  z-index: 0;
  background: linear-gradient(45deg, #ff6600d9, #ad27ad, #bd2681, #6512b9, #ff3300de, #5aabde, limegreen);
  background-size: 400%;
  /* animation: Mini-Profile-BG-Glower 30s linear infinite; */
  filter: blur(40px);
}

@keyframes Mini-Profile-BG-Glower {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
