.Profile-Activity-Container {
  display: flex;
  position: relative;
}
.Profile-Activity-Left {
  width: 280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px 10px;
  padding-top: 6px;
  position: sticky;
  top: 54px;
  box-sizing: border-box;
}
.Profile-Activity-Right {
  max-width: 740px;
  width: 100%;
}
.Profile-Activity-Right > .Feed-Container {
  border: none;
  background: none;
}
.Profile-Activity-Left > div {
  width: 100%;
  padding: 10px;
  font-size: 1.8rem;
  cursor: pointer;
  color: var(--font-color-alt);
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 2px 0px;
  box-sizing: border-box;
  border-bottom: 1px solid transparent;
  box-shadow: 0 0 6px rgb(0 0 0) inset;
  transition: all 0.5s;
}
.Profile-Activity-Left > div:hover {
  background: #18181b;
  color: var(--font-color);
  box-shadow: 0 0 6px rgb(0 0 0);
}
.Profile-Activity-Link-Active {
  background: #18181b;
  color: var(--font-color) !important;
  box-shadow: 0 0 6px rgb(0 0 0) !important;
}

.Profile-Activity-Mobile-Menu-Button {
  color: white;
  position: sticky;
  top: 60px;
  left: 10px;
  box-shadow: 0 0 6px rgb(0 0 0) inset;
  background: #0f0f0f;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  display: none;
  z-index: 1;
  width: 45px;
  height: 45px;
  box-sizing: border-box;
}
.Profile-Activity-Mobile-Menu-Button > svg {
  font-size: 2.5rem;
}

@media screen and (max-width: 950px) {
  /* .Tab-Header-Mobile {
    display: block;
    margin-bottom: 45px;
  } */
  .Profile-Activity-Mobile-Menu-Button {
    display: flex;
  }
  .Profile-Activity-Container {
    flex-direction: column;
  }
  .Profile-Activity-Left {
    width: 240px;
    position: fixed;
    top: 50px;
    bottom: 0;
    left: -240px;
    background: #0f0f0f;
    z-index: 2;
    box-shadow: 0 0 6px rgb(0 0 0) inset;
    transition: all 500ms;
  }
  .Profile-Activity-Left-Mobile-Menu-Open {
    left: 0;
  }
  .Profile-Settings-Right {
    width: 100%;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
