.Socials-Editor-Container {
  margin-top: 30px;
  margin-right: 15px;
}
.Socials-Editor-Container * {
  text-align: left;
}
.Socials-Editor-Inner-Container {
  display: grid;
  grid-template-columns: calc(50% - 2.5px) calc(50% - 2.5px);
  grid-gap: 5px;
  background: #18181b;
  box-shadow: 0px 0px 10px black inset;
  /* padding: 1rem 1rem 1rem 1rem; */
}
.Upload-Socials-Info {
  /* margin-left: 10px; */
  text-align: left;
}
.Upload-Socials-Info > h1 {
  color: var(--font-color);
  margin-bottom: 0;
}

.Upload-Socials-Info > h2 {
  color: var(--font-color-alt);
}
.Socials-Editor-Item {
  display: flex;
  align-items: center;
  padding: 10px;
  box-shadow: 0px 0px 10px black inset;
}
@media (hover: hover) {
  .Socials-Editor-Item:hover {
    background: #0f0f0f;
    cursor: pointer;
  }
}
.Socials-Editor-Item-Alt {
  display: flex;
  align-items: center;
  padding: 10px;
  box-shadow: 0px 0px 10px black inset;
}
.Socials-Editor-Item-Img {
  height: 100px;
  width: 100px;
}
.Socials-Editor-Item-Add {
  height: 100px;
  width: 100px;
  display: flex;
}
.Socials-Editor-Item-Add > svg {
  font-size: 8rem;
  color: var(--font-color-alt);
  margin: auto;
}
.Socials-Editor-Item-Input {
  border-radius: 3px;
  margin-left: 10px;
  width: calc(100% - 150px);
  background: #18181b;
}
.Socials-Editor-Item-Info {
  margin-left: 10px;
  width: calc(100% - 134px);
  box-sizing: border-box;
}
.Socials-Editor-Item-Info > h2 {
  color: var(--font-color);
}
.Socials-Editor-Item-Info > p {
  font-size: 1.2rem;
  color: var(--font-color-alt);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.Socials-Editor-Item-Input-Container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 70%;
}
.Socials-Editor-Item-Input-Container > input {
  width: 95%;
  height: 40px;
  background: #18181b;
  border: none;
  outline: none;
  color: rgb(220, 221, 222);
  font-size: 16px;
  padding: 10px 9px;
  resize: none;
  transition: 150ms;
  transition-property: background-color, border-color, color;
  unicode-bidi: plaintext;
  overflow-x: hidden;
  overflow-wrap: break-word;
  margin: 5px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgb(0 0 0) inset;
}

.Socials-Editor-Item-Input-Container > input:focus {
  outline: 1px solid var(--primary-accent);
}
.Socials-Editor-Item-Options {
  display: flex;
  flex-direction: column;
  margin-left: auto;
}
.Socials-Editor-Item-Options-Alt {
  height: 100px;
}
.Socials-Editor-Item-Option {
  color: var(--font-color-alt);
  margin: auto;
  padding: 2px;
}
.Socials-Editor-Item-Option > svg {
  font-size: 2rem;
}
@media (hover: hover) {
  .Socials-Editor-Item-Option:hover {
    color: var(--font-color);
    cursor: pointer;
  }
}

@media screen and (max-width: 950px) {
  .Socials-Editor-Container {
    margin: 0;
  }
  .Upload-Socials-Info > h1 {
    display: none;
  }
  .Socials-Editor-Inner-Container {
    display: flex;
    flex-direction: column;
  }
  .Upload-Socials-Info > h2 {
    text-align: center;
  }
  .Socials-Editor-Item-Input-Container > input {
    background: #0f0f0f;
  }
}
@media screen and (max-width: 530px) {
  .Socials-Editor-Item-Img {
    height: 50px;
    width: 50px;
  }
  .Socials-Editor-Item-Add {
    height: 50px;
    width: 50px;
    display: flex;
  }
  .Socials-Editor-Item-Add > svg {
    font-size: 4rem;
  }
}
