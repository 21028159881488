.Mini-Profile-Editor-Container {
  /* margin: 10px; */
  margin-top: 30px;
}
.Mini-Profile-Editor-Preview-Container {
  display: flex;
}
.Mini-Profile-Editor-Right {
  margin-left: 10px;
  text-align: left;
}
.Mini-Profile-Editor-Right > h1 {
  color: var(--font-color);
  margin-bottom: 0;
}
@media screen and (max-width: 950px) {
  .Mini-Profile-Editor-Right > h1 {
    display: none;
  }
}

.Mini-Profile-Editor-Right > h2 {
  color: var(--font-color-alt);
}
.Mini-Profile-Editor-Preview {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Past-Mini-Background-Container * {
  box-sizing: border-box;
}
.Past-Mini-Background-Container {
  margin: 10px;
  padding: 10px 10px;
  position: relative;
  background: #18181b;
  box-shadow: 0 0 6px rgb(0 0 0) inset;
  margin-top: 10px;
  color: var(--font-color);
}
.Past-Mini-Background-Container > h2 {
  margin-top: 0;
  text-align: left;
}

.Past-Mini-Background-Container-Inner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
}

.Past-Mini-Background-Item {
  /* box-shadow: 0 0 10px rgb(0 0 0); */
  position: relative;
  z-index: 0;
}
.Past-Mini-Background-Item:hover {
  outline: 2px solid var(--primary-accent);
  cursor: pointer;
}

.Past-Mini-Background-Item-Video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top right;
  cursor: pointer;
  border-radius: 0px;
  aspect-ratio: 16/9;
}

.Upload-Mini-Profile-Background-Button {
  display: flex;
  height: 50px;
}
.Upload-Mini-Profile-Background-Button > button {
  text-decoration: none;
  color: var(--font-color);
  background: var(--background-tertiary);
  display: flex;
  align-items: center;
  padding: 8px;
  padding-top: 2px;
  padding-bottom: 4px;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 3px;
  min-width: 150px;
}
.Upload-Mini-Profile-Background-Button > button > span {
  font-size: 1.6rem;
  width: 100%;
}
.Upload-Mini-Profile-Background-Button > button {
  position: relative;
  cursor: pointer;
  box-sizing: content-box;
  border: 1px solid transparent;
  transition: 0.5s all;
}
.Upload-Mini-Profile-Background-Button > button:hover {
  background-color: var(--background-primary);
  color: var(--primary-accent);
}
.Upload-Mini-Profile-Background-Button > button * {
  cursor: pointer;
}
.Upload-Mini-Profile-Background-Button > button::before,
.Upload-Mini-Profile-Background-Button > button::after,
.Upload-Mini-Profile-Background-Button > button > span::before,
.Upload-Mini-Profile-Background-Button > button > span::after {
  display: block;
  content: '';
  width: 6%;
  height: 20%;
  position: absolute;
}
.Upload-Mini-Profile-Background-Button > button::before {
  top: -2px;
  left: -2px;
  border-top-left-radius: 3px;
  transition: 1s all;
}
.Upload-Mini-Profile-Background-Button > button::after {
  top: -2px;
  right: -2px;
  border-top-right-radius: 3px;
  transition: 1s all;
}
.Upload-Mini-Profile-Background-Button > button:hover::before {
  height: 100%;
  border-top: 1px solid var(--seiso-border);
  border-left: 1px solid var(--seiso-border);
}
.Upload-Mini-Profile-Background-Button > button:hover::after {
  width: 90%;
  border-top: 1px solid var(--seiso-border);
  border-right: 1px solid var(--seiso-border);
}

.Upload-Mini-Profile-Background-Button > button > span::before {
  bottom: -1px;
  left: -2px;
  border-bottom-left-radius: 3px;
  transition: 1s all;
}
.Upload-Mini-Profile-Background-Button > button > span::after {
  bottom: -1px;
  right: -2px;
  border-bottom-right-radius: 3px;
  transition: 1s all;
}
.Upload-Mini-Profile-Background-Button > button:hover > span::before {
  border-bottom: 1px solid var(--seiso-border);
  border-left: 1px solid var(--seiso-border);
  width: 90%;
}
.Upload-Mini-Profile-Background-Button > button:hover > span::after {
  border-bottom: 1px solid var(--seiso-border);
  border-right: 1px solid var(--seiso-border);
  height: 100%;
}

@media screen and (max-width: 950px) {
  .Mini-Profile-Editor-Container {
    margin: 0;
  }
  .Mini-Profile-Editor-Preview-Container {
    flex-direction: column;
  }
}
@media screen and (max-width: 640px) {
  .Past-Mini-Background-Container-Inner {
    grid-template-columns: 1fr 1fr;
  }
}
