.Chat-Container {
  margin-right: 0;
  background-color: var(--background-floating);
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 50px);
  position: relative;
  /* z-index: 10; */
  padding-top: 6px;
}
