.Info-Showcase-Text-Container {
  width: 100%;
  border-radius: 0px;
  padding: 1rem 1rem 1rem 1rem;
  display: inline-grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  /* box-shadow: 0px 0px 10px black; */
  background: #18181b;
  box-shadow: 0px 0px 10px black inset;
}
.Info-Showcase-Text {
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: hidden;
  font-size: 1.5rem;
  color: var(--font-color);
  text-align: left;
}
