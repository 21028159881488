.Socials-Showcase-Container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  width: 100%;
  padding: 1rem 1rem 1rem 1rem;
  box-shadow: 0px 0px 10px black;
  background: #18181b;
  box-shadow: 0px 0px 10px black inset;
}
.Socials-Showcase-Item {
  display: flex;
  align-items: center;
  padding: 10px;
  text-decoration: none;
}
.Socials-Showcase-Item:hover {
  background: var(--background-hover-alt);
  cursor: pointer;
}
.Socials-Showcase-Item-Img {
  height: 100px;
  width: 100px;
}
.Socials-Showcase-Item-Info {
  margin-left: 10px;
  text-align: left;
}
.Socials-Showcase-Item-Info > h2 {
  color: var(--font-color);
}
.Socials-Showcase-Item-Info > p {
  font-size: 1.2rem;
  color: var(--font-color-alt);
}
@media screen and (max-width: 800px) {
  .Socials-Showcase-Container {
    display: flex;
    flex-direction: column;
  }
}
