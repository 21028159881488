.EmojiPickerReact .epr-search-container {
  /* flex: 1; */
  display: block;
  min-width: 0;
  width: calc(100% - 50px);
}

.EmojiPickerReact .epr-search-container input.epr-search {
  outline: none;
  transition: all 0.2s ease-in-out;
  color: var(--epr-search-input-text-color);
  box-shadow: 0px 0px 10px black inset;
  padding: var(--epr-search-input-padding);
  height: var(--epr-search-input-height);
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
  transition: all 0.15s ease-in-out !important;
  border: 1px solid transparent;
  font-size: 1.6rem;
}

.EmojiPickerReact .epr-search-container button.epr-btn-clear-search .epr-icn-clear-search {
  /* background-image: svg-load(./svg/times.svg); */
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 20px;
  height: 20px;
  width: 20px;
}

.EmojiPickerReact .epr-search-container button.epr-btn-clear-search {
  position: absolute;
  right: var(--epr-search-bar-inner-padding);
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  /* border-radius: 50%; */
}

.EmojiPickerReact .epr-search-container button.epr-btn-clear-search:hover .epr-icn-clear-search,
.EmojiPickerReact .epr-search-container button.epr-btn-clear-search:focus .epr-icn-clear-search {
  background-position-y: -20px;
}

.EmojiPickerReact .epr-search-container button.epr-btn-clear-search:hover,
.EmojiPickerReact .epr-search-container button.epr-btn-clear-search:focus {
  background: var(--epr-hover-bg-color);
}

.EmojiPickerReact .epr-dark-theme .epr-search-container button.epr-btn-clear-search .epr-icn-clear-search {
  background-position-y: -40px;
}

.EmojiPickerReact .epr-dark-theme .epr-search-container button.epr-btn-clear-search:hover .epr-icn-clear-search {
  background-position-y: -60px;
}

.EmojiPickerReact .epr-search-container .epr-icn-search {
  content: '';
  position: absolute;
  top: 50%;
  left: var(--epr-search-bar-inner-padding);
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='40' xml:space='preserve'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23868686' d='M12 8.81c0 2.08-1.68 3.76-3.76 3.76s-3.76-1.68-3.76-3.76 1.68-3.76 3.76-3.76S12 6.73 12 8.81zm-.77 3.91c-.83.64-1.87 1.01-2.99 1.01-2.72 0-4.92-2.2-4.92-4.92 0-2.72 2.2-4.92 4.92-4.92 2.72 0 4.92 2.2 4.92 4.92 0 1.13-.38 2.16-1.01 2.99l3.94 3.93c.25.25.25.66 0 .92-.25.25-.66.25-.92 0l-3.94-3.93z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23C0C0BF' d='M12 28.81c0 2.08-1.68 3.76-3.76 3.76s-3.76-1.68-3.76-3.76 1.68-3.76 3.76-3.76S12 26.73 12 28.81zm-.77 3.91c-.83.64-1.87 1.01-2.99 1.01-2.72 0-4.92-2.2-4.92-4.92 0-2.72 2.2-4.92 4.92-4.92 2.72 0 4.92 2.2 4.92 4.92 0 1.13-.38 2.16-1.01 2.99l3.94 3.93c.25.25.25.66 0 .92-.25.25-.66.25-.92 0l-3.94-3.93z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 20px;
}

.EmojiPickerReact.epr-dark-theme .epr-search-container .epr-icn-search {
  background-position-y: -20px;
}

.EmojiPickerReact .epr-search-container input.epr-search::placeholder {
  color: var(--epr-search-input-placeholder-color);
}

.EmojiPickerReact .epr-search-container input.epr-search:focus {
  border: 1px solid var(--primary-accent);
}
