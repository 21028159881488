.General-Editor-Container {
  color: var(--font-color);
  margin-top: 30px;
  text-align: left;
  width: 100%;
}

.General-Editor-Container > h2 {
  color: var(--font-color-alt);
  margin-bottom: 2px;
}
.General-Editor-Textarea {
  width: 95%;
  min-height: 50px;
  background-color: var(--background-floating);
  border: none;
  outline: none;
  color: rgb(220, 221, 222);
  font-size: 16px;
  padding: 10px 9px;
  resize: none;
  transition: 150ms;
  transition-property: background-color, border-color, color;
  unicode-bidi: plaintext;
  overflow-x: hidden;
  overflow-wrap: break-word;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgb(0 0 0) inset;
  text-align: left;
}
.General-Editor-Textarea:disabled {
  border-color: rgb(79, 84, 92);
  background: transparent;

  color: rgb(114, 118, 125);
}
.General-Editor-Textarea::placeholder {
  color: rgb(185, 187, 190);
}
.General-Editor-Textarea:invalid {
  box-shadow: none;
}
.General-Editor-Textarea:focus {
  outline: 1px solid var(--primary-accent);
}

@media screen and (max-width: 950px) {
  .General-Editor-Container {
    margin: 0;
    text-align: center;
  }
  .General-Editor-Container > h1 {
    display: none;
  }
  .General-Editor-Textarea {
    width: 100%;
  }
}
