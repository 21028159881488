.Sayso-Emoji-Picker-Container {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;

  height: 500px;
  width: 500px;
  /* box-shadow: 0 5px 10px black !important; */
  box-shadow: rgb(39, 41, 44) 0px 2px 12px;
  background: rgb(24, 24, 27);
  border: 1px solid black !important;
  box-sizing: border-box;
}
/* .Sayso-Emoji-Picker-Container > div {
  height: 100%;
} */
em-emoji-picker {
  --background-rgb: 85, 170, 255;
  --border-radius: 0px;
  --category-icon-size: 24px;
  --color-border-over: rgba(0, 0, 0, 0.1);
  --color-border: rgba(0, 0, 0, 0.05);
  /* --font-family: 'fujimaru'; */
  --font-size: 1.5rem;
  --rgb-accent: var(--primary-accent-rgb);
  --rgb-background: 24, 24, 27;
  --rgb-color: var(--primary-accent-rgb);
  --rgb-input: 15, 15, 15;
  --shadow: 5px 5px 15px -8px black;

  height: 100%;
  width: 500px;
}
@media screen and (max-width: 650px) {
  .Sayso-Emoji-Picker-Container {
    position: fixed;
    right: 0;
    max-height: unset;
    height: unset;
    width: unset;
    bottom: 0;
  }
  em-emoji-picker {
    width: unset;
  }
}
