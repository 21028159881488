.Profile-Page {
  /* background-color: #1f1f1f; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.Profile-Page-Center-Content {
  background-color: rgba(15, 15, 15);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: var(--profile-max-width);
  width: 100%;
  border-right: 1px solid var(--border-color-alt);
  border-left: 1px solid var(--border-color-alt);
}

.Profile-Body {
  background-color: rgba(15, 15, 15, 0.7);
  backdrop-filter: blur(6px);
  min-height: calc(100vh - 50px);
  max-width: var(--profile-max-width);
  width: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.9);
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  box-shadow: 0px 0px 10px black inset;
}
.Profile-Stream-Elements-Container {
  width: 100%;
  box-sizing: border-box;
}
.Profile-Stream-Container {
  background-color: #0f0f0f;
  width: 100%;
  display: flex;
}
.Profile-Chat-Container {
  width: 350px;
  height: 100%;
  /* position: absolute;
  top: 0;
  right: 0; */
}
.Profile-Custom-Block {
  position: relative;
  padding: 10px 10px 11px 10px;
  margin-bottom: 0px;
  overflow: hidden;
}
.Profile-Custom-Block * {
  box-sizing: border-box;
}
.Profile-Content-Item {
  border-bottom: 1px solid rgb(41, 43, 47);
  margin-top: 20px;
  box-sizing: 3px;
  padding: 10px;
  /* box-shadow: 0 1px 5px black inset; */
}
.Profile-Content-Item * {
  box-sizing: border-box;
}
.Profile-Tab-Container {
  position: relative;
  z-index: 1;
  /* padding: 0px 20px;
  padding-top: 20px; */
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(15, 15, 15, 0.7);
  backdrop-filter: blur(6px);
}
.Profile-Tabs {
  background: rgba(24, 24, 27, 0.3);
  backdrop-filter: blur(6px);
  max-width: var(--profile-max-width);
  display: flex;
  height: 50px;
  box-shadow: 0px 0px 10px black inset;
}
.Profile-Tab {
  color: var(--font-color-alt);
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-left: 18px;
  padding-right: 18px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}
@media screen and (max-width: 650px) {
  .Profile-Tabs {
    justify-content: space-evenly;
  }
  .Profile-Tab {
    width: 100%;
  }
  .Profile-Content-Item {
    padding: 10px 0px;
  }
}
.Profile-Tab > span {
  display: block;
  height: 15px;
  text-transform: uppercase;
  font-weight: 600;
}
.Profile-Tab:hover {
  /* background: #18181b; */
  color: var(--font-color);
  border-bottom: 2px solid var(--primary-accent);
}
.Profile-Tab-Active {
  border-bottom: 2px solid var(--primary-accent);
  color: var(--font-color);
}

.Profile-Custom-Header {
  text-align: left;
  padding: 0 10px;
  font-weight: 600;
  color: var(--font-color-alt);
}
.Profile-Custom-Header-Top {
  font-size: 2rem;
  margin-bottom: 4px;
}
.Profile-Custom-Header-Desc {
  font-size: 1.2rem;
}
.Profile-Page-None-Found {
  font-size: 2rem;
  color: var(--font-color-alt);
  font-weight: 600;
  border: 2px dashed var(--border-color-alt);
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(6px);
  max-width: 300px;
  width: 100%;
  justify-self: center;
  margin: auto;
  margin-top: 20%;
  padding: 40px;
  box-shadow: 0px 0px 10px black inset;
}
.No-Showcases-Message {
  font-size: 2rem;
  color: var(--font-color-alt);
  font-weight: 600;
  border: 2px dashed var(--border-color-alt);
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(6px);
  max-width: 300px;
  width: 100%;
  justify-self: center;
  margin: auto;
  margin-top: 5%;
  padding: 40px;
  box-shadow: 0px 0px 10px black inset;
}
