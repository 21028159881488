.Devtool-Button {
  font-size: 20px;
  width: 100%;
  color: black;
}
.menu {
  background-color: #f3f3f3;
  position: absolute;
}

.menu-item {
  cursor: default;
  padding: 1rem;
}

.menu-item.selected {
  background-color: slateGray;
  color: white;
}

.menu-item:hover:not(.selected) {
  background-color: #fafafa;
}
.SeisoEditorTheme__hashtag {
  color: var(--primary-accent);
}
.SeisoEditorTheme__hashtag:hover {
  text-decoration: underline;
}
.post-img {
  height: 2.8rem;
  aspect-ratio: 1/1;
  margin-left: 2px;
  margin-right: 2px;
  vertical-align: -35%;
}

div.facespinner {
  width: 7rem;
  height: 7rem;
  /* background: rgba(0, 0, 0, 0.5); */
  border-radius: 2.2rem;
  display: flex;
  justify-content: center;
  position: absolute;
  top: calc(50% - 2.5rem);
  left: calc(50% - 3.5rem);
}
div.facespinner__eye {
  width: 2.5rem;
  position: relative;
  transform: translate(-50%, 20%);
  animation: facespinner 3s infinite cubic-bezier(0.76, 0, 0.24, 1) both;
}
div.facespinner__eye:before,
div.facespinner__eye:after {
  content: '';
  left: 0;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.8rem;
  position: absolute;
  animation: facespinnereye 1.5s 1s infinite;

  box-shadow: 0px 0px 5px var(--primary-accent), 0px 0px 10px var(--primary-accent), 0px 0px 20px var(--primary-accent),
    0px 0px 10px var(--primary-accent);
  background: var(--primary-accent);
}
div.facespinner__eye:after {
  left: inherit;
  right: 0;
}

@keyframes facespinner {
  0% {
    transform: translate(-45%, 20%);
  }
  30% {
    transform: translate(0%, 30%);
  }
  60% {
    transform: translate(45%, 20%);
  }
  80% {
    transform: translate(0%, 30%);
  }
}
@keyframes facespinnereye {
  0% {
    transform: scaleY(1);
  }
  80% {
    transform: scaleY(1);
  }
  85% {
    transform: scaleY(0);
  }
  90% {
    transform: scaleY(1);
  }
  95% {
    transform: scaleY(0);
  }
}

/* .visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: pxToRem(1);
  margin: pxToRem(-1);
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: pxToRem(1);
} */
