.Slider-Toggle {
  box-sizing: border-box;
  border-radius: 0;
  border: solid 3px;
  background-color: black;
  box-shadow: 0 0 10px black inset;
  color: var(--border-color-alt);
  display: block;
  height: 40px;
  position: relative;
  width: 80px;
  transition: color 0.3s;
}
.Slider-Toggle:focus {
  outline: 0;
  box-shadow: 0 0 20px;
}
.Slider-Toggle.Slider-Toggle-Active {
  color: var(--primary-accent);
}

.Slider-Toggle-Inner {
  box-sizing: border-box;
  display: block;
  position: absolute;
  height: 28px;
  width: 28px;
  background: linear-gradient(180deg, black, #313130);
  box-shadow: 0 0 10px black inset;
  top: 3px;
  left: 3px;
  border: solid 3px;
  border-radius: 0;
  transition: left 0.3s;
}
.Slider-Toggle-Active > .Slider-Toggle-Inner {
  left: 42px;
}
