.Explore-Tabs-Container {
  background: var(--background-floating);
  margin: auto;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.9), 0 0px 0px rgba(0, 0, 0, 0.9) !important;
}
.Explore-Tabs-Container-Inner {
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  line-height: 47px;
  height: 48px;
  padding-bottom: 1px;
  display: flex;
  align-items: center;
  position: relative;
}
.Explore-Tab {
  padding: 0 32px;
  height: 100%;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  color: var(--font-color);
}
.Explore-Tab-Active {
  padding: 0 32px;
  height: 100%;
  min-width: 100px;
  position: relative;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  color: var(--primary-accent);
  text-decoration: none;
}
.Explore-Tab-Active:before {
  content: '';
  position: absolute;
  border-bottom: 1px solid var(--primary-accent);
  left: 25%;
  bottom: 0;
  height: 1px;
  width: 50%; /* or 100px */
}
.Explore-Tab:hover {
  color: var(--primary-accent);
}
.Explore-Tab-Active:hover {
  color: var(--primary-accent);
}
