.Gallery-Item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.Gallery-Item:hover > .Delete-Gallery-Item-Icon {
  display: block;
}
.Gallery-Item:hover > img {
  filter: brightness(50%);
}
.Delete-Gallery-Item-Icon {
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--font-color);
  z-index: 1;
  display: none;
}
.Delete-Gallery-Item-Icon > svg {
  font-size: 2rem;
}
.Gallery-Showcase-Item-Image-Editor {
  width: 100%;
  cursor: pointer;
}

.Gallery-Editor-Item-Add {
  width: 100%;
  aspect-ratio: 1/1;
  cursor: pointer;
  display: flex;
  background: #18181b;
  transition: background 0.5s;
  box-shadow: 0px 0px 10px black inset;
}
.Gallery-Editor-Item-Add > svg {
  font-size: 8rem;
  color: var(--font-color-alt);
  margin: auto;
}
.Gallery-Editor-Item-Add:hover {
  outline: solid 2px var(--primary-accent);
  background: #0f0f0f;
}
.Gallery-Editor-Item-Add:hover > svg {
  color: var(--font-color);
}
