.Privacy-Editor-Container {
  color: var(--font-color);
  margin-top: 30px;
  text-align: left;
  width: 100%;
}
.Toggle-Setting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--border-color-alt);
}
.Toggle-Setting {
  color: var(--font-color-alt);
}
