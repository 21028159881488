.Create-Post-Container {
  width: 100%;
  max-width: 750px;
  box-sizing: border-box;
  position: relative;
  transition: all 0.3s;
  padding: 12px;
  padding-top: 16px;
  padding-bottom: 4px;
  margin-bottom: 0px;
  border-bottom: 1px solid rgb(37, 40, 43);
  margin-bottom: 6px;
  /* border-bottom: 0;
    border-top: 0; */
}
.Create-Post-Container-Inner {
  display: flex;
  flex-direction: row;
}
.Create-Post-Left {
  flex-basis: 50px;
  margin-right: 8px;
}
.Create-Post-Right {
  width: calc(100% - 62px);
  display: flex;
  flex-direction: column;
  position: relative;
}

.Create-Post-Handle {
  color: var(--primary-accent);
  text-decoration: none;
}
.Typeahead-Popover {
  box-shadow: rgb(39, 41, 44) 0px 2px 12px;
  border: 1px solid black;
  background: rgb(24, 24, 27);
  width: 100%;
  position: absolute;
  z-index: 2;
}
.Typeahead-Popover > ul {
  margin: 0;
  padding: 10px;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.Typeahead-Popover > ul > div > li > span {
  display: flex;
}
.Typeahead-Popover > ul > div {
  width: 100%;
  cursor: pointer;
}
.Mentions-Menu > ul > li {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
  font-size: 1.5rem;
  text-align: left;
  color: var(--font-color);
  cursor: pointer;
}
.Mentions-Menu > ul > li > .Mention-Info-Container {
  -webkit-line-clamp: 1;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Mentions-Menu > ul > li > .Mention-Info-Container * {
  -webkit-line-clamp: 1;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Mention-Info-Container {
  margin-left: 10px;
}
.Mention-Handle {
  color: var(--font-color-alt);
}
.Emoji-Menu > ul > div > li > span {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
}
.selected {
  box-shadow: 0px 0px 10px black inset;
}
.Post-Mention {
  color: var(--primary-accent);
}
.Post-Input {
  width: 100%;
  min-height: 50px;
  height: 68px;
  background-color: var(--background-floating);
  border: 0px solid transparent;
  border-radius: 3px;
  outline: none;
  color: var(--font-color);
  font-size: 16px;
  padding: 10px 9px;
  resize: none;
  transition: 150ms;
  transition-property: background-color, border-color, color;
  unicode-bidi: plaintext;
  overflow-x: hidden;
  overflow-wrap: break-word;
  text-align: left;
}
.Post-Input:focus {
  border-color: var(--primary-accent);
}
.Post-Input:disabled {
  border-color: rgb(79, 84, 92);
  background: transparent;

  color: rgb(114, 118, 125);
}
.Post-Input::placeholder {
  color: rgb(185, 187, 190);
}
.Post-Input:invalid {
  box-shadow: none;
}

.Create-Post-Emoji {
  height: 2rem;
  width: 2rem;
  margin-left: 3px;
  margin-right: 3px;
  vertical-align: -20%;
}
.Create-Post-Custom-Emoji {
  height: 2.8rem;
  width: 2.8rem;
  margin-left: 3px;
  margin-right: 3px;
  vertical-align: -20%;
}

.Post-Input-Alt {
  width: 100%;
  min-height: 54px;
  box-sizing: border-box;
  /* background-color: var(--background-primary); */
  outline: none;
  color: rgb(220, 221, 222);
  font-size: 1.5rem;
  vertical-align: middle;
  padding: 10px 9px;
  resize: none;
  transition: 150ms;
  transition-property: background-color, border-color, color;
  unicode-bidi: plaintext;
  overflow-wrap: break-word;
  cursor: text;
  text-align: left;
}
.Post-Input-Alt:disabled {
  border-color: rgb(79, 84, 92);
  background: transparent;

  color: rgb(114, 118, 125);
}
.Post-Input-Alt::placeholder {
  color: rgb(185, 187, 190);
}
.Post-Input-Alt:invalid {
  box-shadow: none;
}
.Post-Input-Alt * .Post-Mention:hover {
  text-decoration: none !important;
}
.Post-Input-Alt * .Post-Hashtag:hover {
  text-decoration: none !important;
}
.Post-Input-Alt * .Post-Link:hover {
  text-decoration: none !important;
}

.Create-Post-Button {
  margin-left: auto;
  padding: 9px 30px;
  font-size: 1.6rem;
  background: #18181b;
  color: var(--primary-accent);
  font-weight: 600;
  cursor: pointer;
  border: 1px solid black;
  box-sizing: border-box;
  transition: all 0.2s;
}
@media (hover: hover) {
  .Create-Post-Button:hover {
    color: black;
    opacity: 0.9;
    background: var(--primary-accent);
    border: 1px solid transparent;
    box-shadow: 0px 0px 5px var(--primary-accent), 0px 0px 10px var(--primary-accent),
      0px 0px 20px var(--primary-accent), 0px 0px 10px var(--primary-accent);
  }
}
.Create-Post-Character-Counter {
  font-size: 1.5em;
  margin-left: auto;
  color: var(--font-color-alt);
}
.Create-Post-Character-Counter-Warning {
  color: rgb(250, 166, 26);
}
.Create-Post-Character-Counter-Danger {
  color: rgb(240, 71, 71);
}

.Create-Post-Extra-Content {
  max-height: 600px;
  overflow: hidden;
  padding: 10px;
  display: flex;
}

.Create-Post-Extra-Video-Container {
  position: relative;
  width: 100%;
}

.Create-Post-Video {
  width: 100%;
  max-height: 580px;
  align-self: flex-start;
  border: 1px solid var(--border-color-alt);
  /* border-radius: 16px; */
  display: inline-block;
  overflow: hidden;
  background-color: black;
}

.Create-Post-Upload-Close-Icon {
  position: absolute;
  color: rgb(255, 120, 120);
  backdrop-filter: blur(4px);
  background-color: rgba(24, 24, 27, 0.75);
  /* border-radius: 16px; */
  top: 10px;
  left: 10px;
  height: 2.5rem !important;
  width: 2.5rem !important;
  cursor: pointer;
  z-index: 1;
  padding: 0.5rem;
}
@media (hover: hover) {
  .Create-Post-Upload-Close-Icon:hover {
    background-color: rgba(50, 50, 50, 0.5);
  }
}

.Create-Post-Upload-Edit-Icon {
  position: absolute;
  color: var(--font-color);
  backdrop-filter: blur(4px);
  background-color: rgba(24, 24, 27, 0.75);
  right: 10px;
  bottom: 10px;
  height: 2.5rem !important;
  width: 2.5rem !important;
  cursor: pointer;
  z-index: 1;
  padding: 0.5rem;
}
@media (hover: hover) {
  .Create-Post-Upload-Edit-Icon:hover {
    background-color: rgba(50, 50, 50, 0.5);
  }
}

.Create-Post-Upload-Edit {
  position: absolute;
  color: white;
  backdrop-filter: blur(4px);
  background-color: rgba(24, 24, 27, 0.75);
  /* border-radius: 16px; */
  right: 10px;
  bottom: 10px;
  font-size: 1.8rem;
  font-weight: 500;
  padding: 2px 10px 2px 10px;
  height: 30px;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-items: center;
  box-sizing: border-box;
}
@media (hover: hover) {
  .Create-Post-Upload-Edit:hover {
    background-color: rgba(50, 50, 50, 0.5);
  }
}
.Create-Post-Upload-Edit > span {
  display: block;
}

.Create-Post-Images-Grid {
  align-self: flex-start;
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 4px;
  max-height: 600px;
  /* border-radius: 16px; */
  overflow: hidden;
  border: 1px solid var(--border-color-alt);
}
.Create-Post-Images-Grid-Item {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
  grid-row: span 2 / auto;
  position: relative;
}
.Create-Post-Images-Grid-Item-Alt {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
  position: relative;
}
.Create-Post-Single-Image-Container {
  position: relative;
}
.Create-Post-Image {
  min-width: 0px;
  max-width: 100%;
  max-height: 580px;
  margin: 0px 0px 0px;
  /* border-radius: 16px; */
  border: 1px solid var(--border-color-alt);
  cursor: pointer;
  align-self: flex-start;
}
.Create-Post-Image-Alt {
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.Post-Suggestions-List {
  background-color: var(--background-primary);
  position: absolute;
  width: 100%;
  top: 48px;
  box-shadow: 0 2px 5px black;
  z-index: 2;
  padding: 10px 6px;
  /* border-radius: 4px; */
}
.Post-Suggestions-List > li {
  list-style: none;
  color: var(--font-color);
  cursor: pointer;
  padding: 10px;
  /* border-radius: 4px; */
  font-size: 1.5rem;
}
@media (hover: hover) {
  .Post-Suggestions-List > li:hover {
    background-color: var(--background-floating);
  }
}

.Create-Post-Options-Container {
  border-top: 1px solid rgb(37, 40, 43);
  padding-top: 10px;
  margin-top: 10px;
  padding: 12px 10px;
  display: flex;
  align-items: center;
}

.Create-Post-Options {
  border: 1px solid black;
  box-sizing: border-box;
  height: 42px;

  display: flex;
}
.Create-Post-Option {
  box-sizing: border-box;
  width: 42px;
  height: 100%;
  border-right: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--font-color-alt);
  cursor: pointer;
  background: #18181b;
  position: relative;
}
.Create-Post-Option-Unactive {
  background: unset;
  filter: brightness(50%);
}
.Create-Post-Option:last-child {
  border: none;
}
@media (hover: hover) {
  .Create-Post-Option:hover {
    color: var(--primary-accent);
  }

  .Create-Post-Option-Unactive:hover {
    color: var(--font-color-alt);
  }
}
.Create-Post-Tooltip {
  visibility: hidden;
  min-width: 20px;
  background-color: var(--background-floating);
  color: var(--font-color-alt);
  text-align: center;
  padding: 5px 5px;
  /* border-radius: 6px; */
  position: absolute;
  top: 42px;
  left: 0;
  z-index: 1;
}
@media (hover: hover) {
  .Create-Post-Option:hover .Create-Post-Tooltip {
    visibility: visible;
  }
}
