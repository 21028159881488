.Profile-Socials {
  display: flex;
  /* justify-content: flex-end; */
}
.Social-Icon-Container {
  cursor: pointer;
  margin-right: 5px;
  display: block;
  width: 41px;
  height: 41px;
}
.Social-Icon {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 41px;
  height: 41px; */
  width: 100%;
  height: 100%;
  display: block;
  text-decoration: none;
  margin-top: 5px;
  transition: all 0.2s;
  overflow: hidden;
}
.Social-Icon-Container:hover > .Social-Icon {
  margin-top: 0px;
  /* -webkit-box-reflect: below 1px linear-gradient(transparent, #0005); */
}
.Twitch-Icon {
  box-shadow: 0 8px 14px -4px rgb(145 70 255 / 65%);
}
.Youtube-Icon {
  box-shadow: 0 8px 14px -4px rgb(196 48 43 / 65%);
}
.Twitter-Icon {
  box-shadow: 0 8px 14px -4px rgb(29 161 242 / 65%);
}
.Discord-Icon {
  box-shadow: 0 8px 14px -4px rgb(88 101 242 / 65%);
}
.TikTok-Icon {
  box-shadow: 0 8px 14px -4px rgb(200 200 200 / 35%);
}
