.Post-Menu {
  position: absolute;
  right: 14px;
  top: 10px;
  z-index: 1;
  width: 250px;
  border: 1px solid rgb(39, 41, 44);
  box-shadow: rgb(39, 41, 44) 0px 2px 12px;
  background-color: #0f0f0f;
}
.Post-Menu-Options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Post-Menu-Option {
  box-sizing: border-box;
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px;
  font-size: 1.3rem;
  font-weight: 400;
  color: var(--font-color-alt);
  cursor: pointer;
  border-bottom: 1px solid black;
}
.Post-Menu-Option:hover {
  color: white;
  background: #18181b;
}
.Post-Menu-Option:last-child {
  border: none;
}
.Post-Menu-Option-Red {
  color: rgb(255, 120, 120);
}
.Post-Menu-Option-Red:hover {
  color: rgb(232, 48, 48);
}
@media screen and (max-width: 650px) {
  .Post-Menu {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: unset;
    width: unset;
  }
  .Post-Menu-Option {
    text-align: left;
    padding: 20px;
    font-size: 2rem;
    font-weight: 600;
  }
}
