.Quick-Options-Container {
  display: flex;
  flex-direction: column;
  height: 360px;
  width: 280px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  background-color: var(--background-floating) !important;
  box-shadow: 0 5px 10px black !important;
  padding: 10px;
  pointer-events: auto;
}

.Quick-Option-Divider {
  width: calc(100% - 20px);
  border-bottom: var(--border-color) 1px solid;
  align-self: center;
  margin: 10px 0px;
}

.Quick-Option {
  width: 100%;
  padding: 10px;
  font-size: 1.8rem;
  border-radius: 6px;
  cursor: pointer;
  color: var(--font-color-alt);
  display: flex;
  align-items: center;
  text-decoration: none;
}
@media (hover: hover) {
  .Quick-Option:hover {
    background: var(--background-hover);
    color: var(--font-color);
  }
  .Quick-Option:hover > svg * {
    color: var(--font-color);
  }
}

.Quick-Option-Svg {
  font-size: 2.2rem !important;
  margin-right: 5px;
}
.Quick-Option-Svg * {
  color: var(--font-color-alt);
}
