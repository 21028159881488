.Loading-Cubes-Container {
  padding: 20px 15px;
}
.Loading-Cubes {
  width: 30px;
  height: 30px;
  position: relative;
  margin: auto;
}
.Loading-Cubes .Loading-Cube {
  background-color: var(--primary-accent);
  box-shadow: 0px 0px 5px var(--primary-accent), 0px 0px 10px var(--primary-accent), 0px 0px 20px var(--primary-accent),
    0px 0px 10px var(--primary-accent);
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: Loading-Cubes 1.8s ease-in-out -1.8s infinite both;
  animation: Loading-Cubes 1.8s ease-in-out -1.8s infinite both;
}
.Loading-Cubes .Loading-Cube-2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes Loading-Cubes {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: translateX(20px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(20px) translateY(20px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(20px) translateY(20px) rotate(-180deg);
  }
  75% {
    transform: translateX(0) translateY(20px) rotate(-270deg) scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes Loading-Cubes {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: translateX(20px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(20px) translateY(20px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(20px) translateY(20px) rotate(-180deg);
  }
  75% {
    transform: translateX(0) translateY(20px) rotate(-270deg) scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.Loading-Modal-Container-BG {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: rgba(15, 15, 15, 0.7);
  backdrop-filter: blur(6px);
  z-index: 2;
}

.Loading-Modal-Container {
  width: 300px;
  height: 150px;
  box-sizing: border-box;
  padding: 10px 10px;
  padding-top: 20px;
  position: absolute;
  cursor: pointer;
  box-shadow: 0px 0px 10px black inset;
  background: #0f0f0f;
  margin: auto;
  top: calc(50% - 125px);
  left: calc(50% - 150px);
}
.Loading-Modal-Container > h2 {
  margin-top: 20px;
  color: var(--font-color);
  font-weight: 600;
}
.Loading-Modal-Container > h3 {
  color: var(--font-color-alt);
  font-weight: 600;
}
.Loading-Cubes-Container > h2 {
  margin-top: 20px;
  color: var(--font-color);
  font-weight: 600;
}
