.Image-Embed-Modal-Container-BG {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: rgba(15, 15, 15, 0.7);
  backdrop-filter: blur(6px);
  z-index: 5;
}
.Image-Embed-Modal-Container {
  position: absolute;
  top: 10px;
  width: 600px;
  left: calc(50% - 300px);
  max-height: calc(100vh - 60px);
  background: #0f0f0f;
  margin-top: auto;
  margin-bottom: auto;
  overflow-y: scroll;
  pointer-events: auto;
  /* box-shadow: 0 5px 10px black;
  border: 1px solid black; */
  color: var(--font-color);
  box-shadow: 0px 0px 10px black inset;
}

@media screen and (max-width: 600px) {
  .Image-Embed-Modal-Container {
    width: unset;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
  }
}

.Image-Embed-Modal-Container-Inner {
  padding: 10px;
}
.Image-Embed-Modal-Container-Inner > .Image-Block-Item {
  box-sizing: border-box;
}

.Image-Embed-Modal-Video-Link {
  width: 100%;
  border-radius: 3px;
  margin-bottom: 10px;
}

.Image-Embed-Item-Image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}
.Image-Embed-Item-Image:hover {
  filter: brightness(50%);
}

.Image-Modal-Editor-Item-Add {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  cursor: pointer;
  display: flex;
  background: var(--background-tertiary);
}
.Image-Modal-Editor-Item-Add > svg {
  font-size: 8rem;
  color: var(--font-color-alt);
  margin: auto;
}
.Image-Modal-Editor-Item-Add:hover {
  background: var(--background-primary);
}
.Image-Modal-Editor-Item-Add:hover > svg {
  color: var(--font-color);
}

.Image-Modal-Editor-Section > input {
  width: calc(100% - 2px);
  min-height: 40px;
  height: 40px;
  background-color: var(--background-floating);
  border: none;
  outline: none;
  color: rgb(220, 221, 222);
  font-size: 16px;
  padding: 10px 9px;
  resize: none;
  transition: 150ms;
  transition-property: background-color, border-color, color;
  unicode-bidi: plaintext;
  overflow-x: hidden;
  overflow-wrap: break-word;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgb(0 0 0) inset;
}
.Image-Modal-Editor-Section > input:focus {
  outline: 1px solid var(--primary-accent);
}

.Image-Modal-Editor-Section {
  height: 34px;
  overflow: hidden;
  transition: all 1s ease;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 5px;
}

.Image-Modal-Editor-Delete-Icon {
  position: absolute;
  color: rgb(255, 120, 120);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(24, 24, 27, 0.75);
  /* border-radius: 16px; */
  top: 10px;
  left: 10px;
  height: 3.5rem !important;
  width: 3.5rem !important;
  cursor: pointer;
  z-index: 1;
  padding: 0.5rem;
}
.Image-Modal-Editor-Edit-Icon {
  position: absolute;
  color: var(--font-color);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(24, 24, 27, 0.75);
  right: 10px;
  bottom: 10px;
  height: 3.5rem !important;
  width: 3.5rem !important;
  cursor: pointer;
  z-index: 1;
  padding: 0.5rem;
}
