.App-Background-Editor-Container {
  /* margin: 10px; */
  margin-top: 30px;
}
.App-Background-Editor-Container > h1 {
  color: var(--font-color);
}
.App-Background-Editor-Flex {
  display: flex;
}
.App-Background-Preview-Container {
  padding: 10px 10px;
  margin: 10px;
  margin-top: 20px;
  width: calc(100% - 20px);
  box-sizing: border-box;
}

.Upload-App-Background-Info {
  /* margin-left: 10px; */
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
}
.Upload-App-Background-Info > div > h1 {
  color: var(--font-color);
  /* margin-bottom: 0; */
  margin: 0;
}
.Upload-App-Background-Info > div > h2 {
  color: var(--font-color-alt);
}

.Past-App-Background-Container {
  margin: 10px 20px;
  margin-top: 20px;
  background: #18181b;
  box-shadow: 0 0 6px rgb(0 0 0);
  padding: 10px 10px;
  position: relative;
  color: var(--font-color);
  box-shadow: 0 0 6px rgb(0 0 0) inset;
}
.Past-App-Background-Container > h2 {
  text-align: left;
}
.Past-App-Background-Item {
  /* height: 270px; */
  aspect-ratio: 3.5/1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10px;
  filter: brightness(65%);
}
.Past-App-Background-Item:hover {
  cursor: pointer;
  filter: brightness(100%);
}
.App-Background-Preview-Video {
  width: 100%;
  max-width: 400px;
  aspect-ratio: 16/9;
  box-shadow: 0 0 6px rgb(0 0 0);
  background: #18181b;
}
.App-Background-Preview-Image {
  width: 100%;
  max-width: 400px;
  aspect-ratio: 16/9;
  box-shadow: 0 0 6px rgb(0 0 0);
  background: #18181b;
}
.Upload-App-Background-Button {
  margin-left: auto;
}
