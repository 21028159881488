div.DefaultTheme-Dark {
  --app-background: #0f0f0f;

  --font-color: rgb(255, 255, 255);
  --font-color-alt: rgb(113, 118, 123);

  --border-color: #434651;
  --border-color-alt: rgb(47, 51, 54);
  --youtube-border: rgb(196, 48, 43);
  --twitch-border: rgb(145 70 255);
  --seiso-border: rgb(102, 252, 241);

  --status-live: #eb0400;

  --primary-accent: #66fcf1;

  --picture-rounded: 50%;

  --header-background: rgba(32, 34, 37, 0.75);
  --background-hover: rgba(0, 0, 0, 0.5);
  --background-hover-alt: rgba(255, 255, 255, 0.1);
  --background-primary: #0f0f0f;
  --background-secondary: rgb(32, 34, 37);
  --background-tertiary: #202225;
  /* --background-accent: #4f545c; */
  --background-floating: #18191c;

  --gradient-title-top: white;
  --gradient-title-bottom: #38495a;
}

div.DefaultTheme-LighterDark {
  --app-background: #0f0f0f;
  --app-background-transparent: rgba(15, 15, 15, 0.75);

  --font-color: rgb(255, 255, 255);
  --font-color-alt: rgb(113, 118, 123);

  --border-color: #434651;
  --border-color-alt: rgb(47, 51, 54);
  --youtube-border: rgb(196, 48, 43);
  --twitch-border: rgb(145 70 255);
  --seiso-border: rgb(102, 252, 241);

  --status-live: #eb0400;

  --primary-accent: #66fcf1;
  /* --primary-accent: #ff633a; */

  --picture-rounded: 50%;

  --header-background: rgba(38, 38, 44, 0.75);
  --background-hover: rgba(0, 0, 0, 0.5);
  --background-hover-alt: rgba(255, 255, 255, 0.1);
  --background-primary: #18181b;
  --background-primary-transparent: rgba(24, 24, 27, 0.75);
  --background-secondary: #26262c;
  --background-tertiary: #1f1f23;
  --background-floating: #26262c;

  --gradient-title-top: white;
  --gradient-title-bottom: #38495a;
}

/* div.DefaultTheme-Light {
  --app-background: #f7f7f8;

  --font-color: #0f0f0f;
  --font-color-alt: rgb(80, 80, 80);

  --border-color: #434651;
  --border-color-alt: rgb(47, 51, 54);
  --youtube-border: rgb(196, 48, 43);
  --twitch-border: rgb(145 70 255);
  --seiso-border: rgb(102, 252, 241);

  --status-live: #eb0400;

  --primary-accent: #66fcf1;

  --picture-rounded: 50%;

  --header-background: rgba(255, 255, 255, 0.75);
  --background-hover: rgba(255, 255, 255, 1);
  --background-hover-alt: rgba(255, 255, 255, 0.1);
  --background-primary: #f7f7f8;
  --background-secondary: white;
  --background-tertiary: #202225;
  --background-floating: white;

  --gradient-title-top: black;
  --gradient-title-bottom: grey;
} */

/*App themes*/

div.Theme-I-Like-Squares {
  --pfp-image-shape: 1px;
}
div.Theme-I-Like-Circles {
  --pfp-image-shape: 50px;
}

div.Profile-Page {
  --profile-max-width: 1000px;
}

div.Theme-Default {
  --app-background: #000;

  --font-color: white;
  --font-color-alt: rgb(113 118 123);

  --primary-accent: #66fcf1;

  --primary-accent-rgb: 102, 252, 241;

  --youtube-red: rgb(196 48 43);
  --twitch-purple: rgb(145 70 255);

  --status-live: #eb0400;

  --border-color: #434651;
  --border-color-alt: rgb(47, 51, 54);

  --header-background: rgba(32, 34, 37, 0.75);
  --background-hover: rgba(0, 0, 0, 0.5);
  --background-hover-alt: rgba(255, 255, 255, 0.1);
  --background-primary: #0f0f0f;
  --background-secondary: rgb(32, 34, 37);
  --background-tertiary: #202225;
  --background-floating: #18191c;

  --gradient-title-top: white;
  --gradient-title-bottom: #38495a;
}

div.Theme-Accent-Seiso {
  --primary-accent: #66fcf1 !important;
  --primary-accent-rgb: 102, 252, 241;
}
div.Theme-Accent-Sakura {
  --primary-accent: #ed207b !important;
  --primary-accent-rgb: 237, 32, 123;
}
div.Theme-Accent-MonMon {
  --primary-accent: #00e59b !important;
  --primary-accent-rgb: 0, 229, 155;
}
div.Theme-Accent-Blaze {
  --primary-accent: #ff6600 !important;
  --primary-accent-rgb: 255, 102, 0;
}
div.Theme-Accent-Purp {
  --primary-accent: #9146ff !important;
  --primary-accent-rgb: 145, 70, 255;
}
