body,
html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-family: Lato, Helvetica Neue, helvetica, sans-serif;
  font-weight: 400;
  height: 100%;
  overflow-anchor: none;
  font-size: 10px;
  overscroll-behavior: none;
  right: 0;
  left: 0;
}
html {
  overflow-y: scroll;
  overscroll-behavior-y: none;
  /* height: 100%; */
}
a {
  color: var(--primary-accent);
  text-decoration: none;
}
.Video-Container {
  aspect-ratio: 16/9;
  width: 100%;
}

::-moz-selection {
  /* Code for Firefox */
  color: black;
  background: var(--primary-accent);
}

::selection {
  color: black;
  background: var(--primary-accent);
}

::-webkit-scrollbar {
  height: 12px;
  width: 0px;
  background: transparent;
  z-index: 12;
  overflow: hidden;
}
body::-webkit-scrollbar {
  height: 12px;
  width: 14px;
  background: transparent;
  z-index: 12;
  overflow: visible;
}
body::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: grey;
  z-index: 12;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  transition: background-color 0.32s ease-in-out;
  box-shadow: 0px 0px 4px black inset;
  margin: 4px;
  min-height: 32px;
  min-width: 32px;
}
body::-webkit-scrollbar-track {
  background: #18181b;
  box-shadow: 0px 0px 10px black inset;
}
body::-webkit-scrollbar-corner {
  background: #18181b;
}

.Active-Scrollbar::-webkit-scrollbar {
  height: 12px;
  width: 14px !important;
  background: transparent;
  z-index: 12;
  overflow: visible;
}
.Active-Scrollbar::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: grey;
  z-index: 12;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  transition: background-color 0.32s ease-in-out;
  box-shadow: 0px 0px 4px black inset;
  margin: 4px;
  min-height: 32px;
  min-width: 32px;
}
.Active-Scrollbar::-webkit-scrollbar-track {
  background: #18181b;
  box-shadow: 0px 0px 10px black inset;
}
.Active-Scrollbar::-webkit-scrollbar-corner {
  background: #18181b;
}

@font-face {
  font-family: 'Fujimaru';
  src: url('./fonts/Fujimaru.otf');
}

/*Loading*/

.Loading {
  display: flex;
  justify-content: center;
  padding: 15px;
  position: relative;
}
.Loading::after {
  content: '';
  width: 55px;
  height: 55px;
  border: 5px solid var(--background-floating);
  border-top-color: var(--primary-accent);
  border-radius: 50%;
  animation: Loading 1s linear infinite;
}
@keyframes Loading {
  to {
    transform: rotate(1turn);
  }
}

.Fade_In {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
.Quick_Fade_In {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

/*Animations */
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
