.Notifications-Container {
  max-width: 750px;
  width: 100%;
  background: #0f0f0f;
  min-height: calc(100vh - 50px);
  border-left: 1px solid var(--border-color-alt);
  border-right: 1px solid var(--border-color-alt);
}
.Notifications-Header {
  position: sticky;
  background: rgba(15, 15, 15, 0.75);
  border-bottom: 1px solid var(--border-color-alt);
  backdrop-filter: blur(12px);
  top: 50px;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
}

.Notification-Mention-Post-Container {
  display: flex;
  padding: 12px;
  box-shadow: 0px 0px 10px black inset;
  margin: 10px;
  cursor: pointer;
}
.Notification-Mention-Post-Container-Unread {
  background: #18181b;
}
.Notifications-No-Items-Message {
  font-size: 2rem;
  color: var(--font-color-alt);
  font-weight: 600;
  border: 2px dashed var(--border-color-alt);
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(6px);
  max-width: 300px;
  width: 100%;
  justify-self: center;
  margin: auto;
  margin-top: 5%;
  padding: 40px;
  box-shadow: 0px 0px 10px black inset;
}
