.Thread-Container {
  width: 100%;
  max-width: 750px;
  background: #0f0f0f;
  border-left: 1px solid var(--border-color-alt);
  border-right: 1px solid var(--border-color-alt);
  min-height: calc(100vh - 50px);
}
.Thread-Container > .Feed-Container {
  border: none;
}
