.Panel-Block-Editor-Container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Panel-Editor-Item-Add {
  /* max-width: 320px; */
  height: 392px;
  cursor: pointer;
  display: flex;
  background: #18191c;
  transition: background 0.5s;
  box-shadow: 0px 0px 10px black inset;
}
.Panel-Editor-Item-Add > svg {
  font-size: 8rem;
  color: var(--font-color-alt);
  margin: auto;
}
.Panel-Editor-Item-Add:hover {
  outline: solid 2px var(--primary-accent);
  background: #0f0f0f;
}
.Panel-Editor-Item-Add:hover > svg {
  color: var(--font-color);
}

.Panel-Showcase-Text > span {
  margin-top: 10px;
  display: block;
}

.Panel-Showcase-Link-Input {
  width: 100%;
  height: 46px;
  background: #0f0f0f;
  border: 2px solid transparent;
  outline: none;
  color: rgb(220, 221, 222);
  font-size: 16px;
  padding: 10px 9px;
  resize: none;
  transition: 150ms;
  transition-property: background-color, border-color, color;
  unicode-bidi: plaintext;
  overflow-wrap: break-word;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgb(0 0 0) inset;
}
.Panel-Showcase-Link-Input:focus {
  border-color: var(--primary-accent);
}

.Panel-Showcase-Desc-Input {
  width: 100%;
  height: 200px;
  background: #0f0f0f;
  /* background: #1c1c1f; */
  border: 2px solid transparent;
  outline: none;
  color: rgb(220, 221, 222);
  font-size: 15px;
  padding: 10px 9px;
  resize: none;
  transition: 150ms;
  transition-property: background-color, border-color, color;
  unicode-bidi: plaintext;
  overflow-wrap: break-word;
  cursor: text;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgb(0 0 0) inset;
}

.Panel-Showcase-Desc-Input:focus {
  border-color: var(--primary-accent);
}

.Panel-Showcase-Desc-Input::-webkit-scrollbar-track {
  background: transparent !important;
}

.Add-Panel-Image-Button {
  background: var(--primary-accent);
  color: black;
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 6px 20px;
  border-radius: 4px;
  border: none;
  width: 102px;
}
.Add-Panel-Image-Button:hover {
  filter: brightness(80%);
}

.Remove-Panel-Button {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 6px 20px;
  margin-top: 4px;
  border-radius: 8px;
  color: var(--primary-accent);
}
.Remove-Panel-Button:hover {
  text-decoration: underline;
}
.Remove-Panel-Button-Container {
  margin-left: auto;
}

.Panel-Showcase-Img-Editor {
  width: 100%;
}
.Panel-Showcase-Img-Editor:hover {
  filter: brightness(80%);
  cursor: pointer;
}
