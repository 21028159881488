.EmojiPickerReact .epr-category-nav {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  position: absolute;
  z-index: 5;
  background: #0f0f0f;
  top: 70px;
  bottom: 0px;
  right: 0;
  overflow-y: scroll;
}

.EmojiPickerReact .epr-category-nav > button.epr-cat-btn {
  margin-bottom: 10px;
  display: inline-block;
  transition: opacity 0.2s ease-in-out;
  position: relative;
  height: var(--epr-category-navigation-button-size);
  width: var(--epr-category-navigation-button-size);
  background-size: calc(var(--epr-category-navigation-button-size) * 10);
  outline: none;
}

.EmojiPickerReact .epr-category-nav > button.epr-cat-btn:focus:before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  /* border: 2px solid var(--epr-category-icon-active-color); */
  /* border-radius: 50%; */
}

aside.EmojiPickerReact.epr-main:has(input:not(:placeholder-shown)) .epr-category-nav {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

.EmojiPickerReact.epr-search-active .epr-category-nav {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}
aside.EmojiPickerReact.epr-main:has(input:not(:placeholder-shown)) .epr-category-nav {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}
.EmojiPickerReact.epr-search-active .epr-category-nav {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}
aside.EmojiPickerReact.epr-main:has(input:not(:placeholder-shown)) .epr-category-nav > button.epr-cat-btn:hover {
  opacity: 1;
  background-position-y: var(--epr-category-navigation-button-size);
}
.EmojiPickerReact:not(.epr-search-active) .epr-category-nav > button.epr-cat-btn:hover,
.EmojiPickerReact:not(.epr-search-active) .epr-category-nav > button.epr-cat-btn.epr-active {
  opacity: 1;
  background-position-y: var(--epr-category-navigation-button-size);
}
.EmojiPickerReact .epr-category-nav > button.epr-cat-btn {
  /* background-image: svg-load(./svg/CategoryNav.svg); */
  background-size: contain !important;
  background-position: 0 0;
}

.EmojiPickerReact.epr-dark-theme .epr-category-nav > button.epr-cat-btn {
  background-position-y: calc(var(--epr-category-navigation-button-size) * 2);
  min-height: 30px;
}

aside.EmojiPickerReact.epr-main:has(input:placeholder-shown) .epr-category-nav {
  background-position-y: calc(var(--epr-category-navigation-button-size) * 3);
}
.EmojiPickerReact.epr-dark-theme:not(.epr-search-active) .epr-category-nav > button.epr-cat-btn:hover,
.EmojiPickerReact.epr-dark-theme:not(.epr-search-active) .epr-category-nav > button.epr-cat-btn.epr-active {
  background-position-y: calc(var(--epr-category-navigation-button-size) * 3);
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-suggested {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -8);
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-activities {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -4);
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-animals_nature {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -1);
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-flags {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -7);
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-food_drink {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -2);
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-objects {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -5);
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-smileys_people {
  background-position-x: 0px;
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-symbols {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -6);
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-travel_places {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -3);
}
