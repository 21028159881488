.Image-Embed-Modal-Container-BG {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: rgba(15, 15, 15, 0.7);
  backdrop-filter: blur(6px);
  z-index: 5;
}
.Video-Embed-Modal-Container {
  position: absolute;
  top: 10px;
  width: 600px;
  left: calc(50% - 300px);
  max-height: calc(100vh - 60px);
  background: #0f0f0f;
  margin-top: auto;
  margin-bottom: auto;
  overflow-y: scroll;
  pointer-events: auto;
  /* box-shadow: 0 5px 10px black;
  border: 1px solid black; */
  color: var(--font-color);
  box-shadow: 0px 0px 10px black inset;
}

@media screen and (max-width: 600px) {
  .Video-Embed-Modal-Container {
    width: unset;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
  }
}

.Video-Embed-Modal-Container-Inner {
  padding: 10px;
}
.Video-Embed-Modal-Container-Inner > .Video-Showcase-Item {
  box-sizing: border-box;
}

.Video-Embed-Modal-Video-Link {
  width: 100%;
  margin-bottom: 10px;
}

.Video-Modal-Editor-Section {
  height: 34px;
  overflow: hidden;
  transition: all 1s ease;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 5px;
}

.Video-Modal-Editor-Section > input {
  width: calc(100% - 2px);
  min-height: 40px;
  height: 40px;
  background-color: var(--background-floating);
  border: none;
  outline: none;
  color: rgb(220, 221, 222);
  font-size: 16px;
  padding: 10px 9px;
  resize: none;
  transition: 150ms;
  transition-property: background-color, border-color, color;
  unicode-bidi: plaintext;
  overflow-x: hidden;
  overflow-wrap: break-word;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgb(0 0 0) inset;
}
.Video-Modal-Editor-Section > input:focus {
  outline: 1px solid var(--primary-accent);
}
