.Block-Text {
  width: calc(100% - 10px);
  height: 50px;
  background: #18181b;
  border: none;
  outline: none;
  color: rgb(220, 221, 222);
  font-size: 16px;
  padding: 10px 9px;
  resize: none;
  transition: 150ms;
  transition-property: background-color, border-color, color;
  unicode-bidi: plaintext;
  overflow-x: hidden;
  overflow-wrap: break-word;
  margin: 5px;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgb(0 0 0) inset;
}
.Block-Text:focus {
  outline: 1px solid var(--primary-accent);
}
.Block-Text:disabled {
  border-color: rgb(79, 84, 92);
  background: transparent;

  color: rgb(114, 118, 125);
}
.Block-Text::placeholder {
  color: var(--font-color-alt);
}
.Block-Text:invalid {
  box-shadow: none;
}

.Block-Custom-Header {
  font-family: 'Motiva Sans', Sans-serif;
  font-weight: 200;
  padding: 5px 10px 5px 10px;
  color: var(--font-color);
  font-size: 16px;
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
}
.Block-Custom-Header > h2 {
  margin: 0;
}

.Editor-Block-Section-Top-Level {
  padding-bottom: 6px;
  padding-bottom: 8px;
  border-radius: 0px;
  padding: 10px 10px;
  position: relative;
  box-shadow: 0 1px 5px black inset;
  margin-top: 10px;
  /* background: #050505; */
}

.Editor-Block-Section {
  height: 48px;
  overflow: hidden;
  transition: all 1s ease;
}
.Editor-Block-Section-Alt {
  height: 48px;
  overflow: hidden;
  transition: all 1s ease;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 5px;
}

.Editor-Block-Section-Open {
  height: auto !important;
}

.Selection-Header {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  padding: 5px;
  margin-bottom: 10px;
}
.Selection-Header > span {
  font-weight: 600;
  color: var(--font-color);
  cursor: pointer;
  user-select: none;
}
.Selection-Header > svg {
  font-size: 2rem;
  cursor: pointer;
  color: var(--font-color);
}
.Selection-Header-Alt {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  padding: 5px;
  margin-bottom: 3px;
}
.Selection-Header-Alt > span {
  font-weight: 600;
  color: var(--font-color-alt);
  cursor: pointer;
  user-select: none;
}
.Selection-Header-Alt > svg {
  font-size: 2rem;
  cursor: pointer;
  color: var(--font-color-alt);
}

.Editor-Block-Section-Open > .Selection-Header > .Selection-Header-Chevron {
  transform: rotate(90deg);
}
.Editor-Block-Section-Open > .Selection-Header-Alt > .Selection-Header-Chevron {
  transform: rotate(90deg);
}

.Section-Options {
  margin-left: auto;
  display: flex;
}
.Section-Options-Button {
  display: block;
  margin: 0px;
  padding: 0px;
  border: none;
  background: none;
  outline: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
}
.Section-Options-Button:hover > div {
  color: var(--font-color);
}

.Section-Options-Button > div {
  padding: 8px;
  margin: -8px 0px;
  align-self: baseline;
  color: var(--font-color-alt);
}
.Section-Options-Button > span {
  border: 0px none;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  overflow-wrap: normal;
}

.Display-Count-Options {
  display: flex;
  padding-bottom: 10px;
}
.Display-Count-Option {
  display: block;
  margin: 0px;
  padding: 0px;
  border: none;
  background: none;
  outline: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
}

.Display-Count-Option:hover > div {
  color: var(--font-color);
}

.Display-Count-Option > div {
  padding: 8px;
  margin: -8px 0px;
  align-self: baseline;
  color: var(--font-color-alt);
}
.Display-Count-Option > div > svg {
  font-size: 2.5rem;
}

.Display-Count-Option-Active > div {
  color: var(--font-color);
}
